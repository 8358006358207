import React from 'react';
import * as sc from '../../../../../../common/styled/styled';
import * as c from '../../../../../../common/colors';
import { CommonLabel, Img } from '../../../../../../common/FormInputs/Common';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { NPM_TEMPLATE } from '../../../../../../global-constants';
import Unchecked from '../../../../../../assets/img/uncheck.svg';
import DisableChecked from '../../../../../../assets/img/disableChecked2.svg';
import Checked from '../../../../../../assets/img/checked.svg';
import InfoIcon from '../../../../../../assets/img/C-info.svg';
import { CardWrapper } from './OutcomePracticeCard';
import LightTooltip from '../../../../../../common/LightTooltip';

const OtherThanValuesCard = ({ item, index, addRemoveModule, isSelected }) => {
    const { cyan100, Neutral10, whiteShade, primaryCyan, TextHeading } = c.colorsCode;
    const { is_included, group_text } = item;
    const { surveyInformation } = useSelector(state => state?.surveyReducer);
    return (
        <CardWrapper
            key={item.id}
            background={is_included ? cyan100 : whiteShade}
            borderColor={is_included ? primaryCyan : Neutral10}
        >
            <sc.FlexBetween>
                <sc.FlexRow>
                    <LightTooltip title={item?.info_icon_description} placement="top" arrow>
                        <sc.FlexRowCTA>
                            <Img image={InfoIcon} w={c.pxToVw(20)} h={c.pxToVh(20)} />
                        </sc.FlexRowCTA>
                    </LightTooltip>
                    <CommonLabel
                        label={item.group_text}
                        textColor={TextHeading}
                        textWeight={500}
                        textSize={18}
                        lineHeight={28}
                    />
                </sc.FlexRow>
                {surveyInformation?.schema_name !== NPM_TEMPLATE && (
                    <FormControlLabel
                        sx={{ margin: '0' }}
                        control={
                            <Checkbox
                                disabled={isSelected}
                                value={group_text}
                                checked={item.is_included}
                                icon={
                                    <img
                                        src={Unchecked}
                                        alt=""
                                        style={{
                                            height: `${c.pxToVh(24)}vh`,
                                            width: `${c.pxToVw(24)}vw`,
                                        }}
                                    />
                                }
                                checkedIcon={
                                    <img
                                        src={isSelected ? DisableChecked : Checked}
                                        alt=""
                                        style={{
                                            height: `${c.pxToVh(24)}vh`,
                                            width: `${c.pxToVw(24)}vw`,
                                        }}
                                    />
                                }
                                onClick={() => addRemoveModule(item, index)}
                                sx={{
                                    height: 0,
                                    width: 0,
                                }}
                            />
                        }
                    />
                )}
            </sc.FlexBetween>
        </CardWrapper>
    );
};

export default OtherThanValuesCard;
