import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Icon,
    Paper,
    Typography,
} from '@mui/material';
import { LABELS, MESSAGE, NPM_TEMPLATE, pulseSurveyTypes, REGEX } from '../../../../global-constants';
import Header from '../create/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAdditionalModules,
    GetAllQuesUnderModule,
    UpdateAdditionalModules,
    removeReasonEmail,
} from '../../../../store/actions/Surveys/action';
import { ViewAllModal } from '../../../../common/DialogModal';
import { cloneDeep, isEmpty } from 'lodash';
import Loader from '../../../Loader';
import PageHeader from './common-components/PageHeader';
import {
    resetData,
    updateErrorState,
    updateSectionQuestion,
    updateTabQuestions,
    setSurveySliceLoading,
} from '../../../../store/reducers/slice/SurveySlice';
import {
    setLoading,
    updateAllOutcomes,
    updateOutcome,
    updateOutcomeQuestion,
    updatePractice,
    updatePracticeQuestion,
} from '../../../../store/reducers/slice/OhiTeplateQuestionsSlice';
import { ShowMessage } from '../../../../common/FormInputs/Snackbar';
import { EditSurveyDiv } from './Wording';
import OptionalQuestions from './OptionalQuestions';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel, Pagetab } from '../../../../common/FormInputs/Common';
import {
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    CTA,
    FlexCenterWithAlign,
    FlexColumnGap,
    FlexInline,
    FlexStartGap,
    QuestionContent,
} from '../../../../common/styled/styled';
import { api } from '../../../../http/api';
import { Endpoints } from '../../../../http/Endpoints';
import OutcomeQuestions, { ModuleWrapper, TabsWrapper } from './QuestionModules/Pulse/OutcomeQuestions';
import { getOutcomesAndPractices } from '../../../../store/actions/TextEdit/actions';
import PracticeSections, { CardWrapper } from './QuestionModules/Pulse/PracticeSections';
import InputField from '../../../../common/FormInputs/InputField';
import { DialogActionsStyled } from './Finalize';
import { additionalModulesConstant } from '../TextEdit/AdditionaSections/constants';
import CloseIcon from '../../../../assets/img/close.svg';
import arrowDown from '../../../../assets/img/Small-down.svg';
import DisabledCheckboxSvg from '../../../../assets/img/DisabledCheckbox.svg';
import OtherThanValuesCard from './QuestionModules/Pulse/OtherThanValuesCard';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SETUP_SURVEY_TEXT } from '../../../../utils/kc-constants';
import c from 'classnames';
import { commonThemes } from '../../../../common/themes';
import OptionalModulesSection from './OptionalModulesSection';

const OptionalModules = ({
    goToNext = () => {},
    isHeaderRequired = true,
    styles,
    isValuesReq = false,
    isOtherThanValue = false,
    hideOptionalQuestions = false,
    hideOutcomesAndPractices = false,
    errorState,
    setErrorState,
    unsaved,
    setUnsaved,
    showErrWarning,
    setShowErrWarning,
}) => {
    const dispatch = useDispatch();
    const stylesComp = commonThemes();
    const [optionalModules = [], setOptionalModules] = React.useState([]);
    const { ADDITIONAL_TTILE, ADDITIONAL_DESC } = LABELS.SURVEY_MODULE.OPTIONAL_MODULES;
    const {
        additionalModules,
        surveyInformation: si = {},
        loading,
        isSaved,
        error,
        errorMessage,
    } = useSelector(state => state.surveyReducer);
    const {
        tabs,
        loading: ohiTempLoading,
        outcomesData: ohiData,
        selectedQuestionCount,
    } = useSelector(state => state.ohiTemp);
    const [open, setOpen] = React.useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reasonObj, setReasonObj] = useState({ reason: '', item: '', index: '' });
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [otherThanValuesTabs, setOtherThanValuesTabs] = React.useState([]);
    const [selectedOtherValueTab, setSelectedOtherValueTab] = React.useState('');
    const [otherThanValuesQuestion, setOtherThanValuesQuestion] = useState([]);

    const showOutcomes = pulseSurveyTypes.includes(si?.schema_name);
    const isPulse = si?.survey_sub_type === 'PULSE';
    const {
        valuesModule: valuesData = [],
        restModules: otherThanValues = [],
        optionalQuesModules: optionalQuesData = [],
        dependentSubGroup: dependentSubGroupInfo,
        pssModule: pssData,
    } = React.useMemo(() => {
        const valuesModule = isValuesReq
            ? optionalModules.filter(({ sub_group_code, is_included }) => is_included && sub_group_code === 'is_values')
            : optionalModules.filter(({ sub_group_code }) => sub_group_code === 'is_values');

        const pssModule = isValuesReq
            ? optionalModules.find(
                  ({ sub_group_code, is_included }) => is_included && sub_group_code === 'pss_questions'
              )
            : optionalModules.find(({ sub_group_code }) => sub_group_code === 'pss_questions');

        const restModules = isOtherThanValue
            ? optionalModules.filter(
                  ({ sub_group_code, is_included }) =>
                      is_included &&
                      !sub_group_code.includes('is_values') &&
                      !sub_group_code.includes('pss_questions') &&
                      !sub_group_code.includes('_optional')
              )
            : optionalModules.filter(
                  ({ sub_group_code }) =>
                      !sub_group_code.includes('is_values') &&
                      !sub_group_code.includes('pss_questions') &&
                      !sub_group_code.includes('_optional')
              );

        const dependentSubGroup = optionalModules.filter(({ disable_questions_uuid }) => disable_questions_uuid);

        const optionalQuesModules = hideOptionalQuestions
            ? []
            : optionalModules.filter(({ sub_group_code }) => sub_group_code.includes('_optional'));

        return {
            valuesModule,
            restModules,
            optionalQuesModules,
            dependentSubGroup,
            pssModule,
        };
    }, [optionalModules, isValuesReq, isOtherThanValue, hideOptionalQuestions]);

    useEffect(() => {
        const tabArr = otherThanValues?.map(item => item.sub_group_type);
        setOtherThanValuesTabs(tabArr);
        setSelectedOtherValueTab(tabArr[0]);
        setOtherThanValuesQuestion(otherThanValues[0]?.sections);
    }, [otherThanValues]);
    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };

    const handleOtionalModuleResponse = async (ques_sub_group_id, is_include) => {
        const url = `${Endpoints.updateAdditionalModules
            .replace(':survey_id', si.uuid)
            .replace(':question_sub_group_id', ques_sub_group_id)}?include=${is_include}`;
        try {
            await api.put(url);
        } catch (error) {
            dispatch(updateErrorState(error?.response?.data?.message || MESSAGE.Error_Add_Client));
        }
    };

    const handleQuestionResponse = async (questionId, use_in_survey, additionalPayload = {}) => {
        try {
            return await api.patch(
                `${Endpoints.saveStandardDemo.replace(':survey_id', si.uuid).replace(':question_id', questionId)}`,
                { use_in_survey, ...additionalPayload }
            );
        } catch (error) {
            dispatch(updateErrorState(error?.response?.data?.message));
            dispatch(setLoading(false));
            dispatch(setSurveySliceLoading(false));
        }
    };

    const handleMultipleQuestionResponse = async (question_ids, use_in_survey) => {
        try {
            return await api.post(`${Endpoints.updateMultipleQuestions.replace(':survey_id', si.uuid)}`, {
                use_in_survey,
                question_ids,
            });
        } catch (error) {
            dispatch(updateErrorState(error?.response?.data?.message));
            dispatch(setLoading(false));
        }
    };

    const updateMultipleQuestions = async (question_ids, use_in_survey) => {
        try {
            return await api.post(
                `${Endpoints.updateMultipleQuestions.replace(':survey_id', si.uuid).concat('?is_standard=true')}`,
                {
                    use_in_survey,
                    question_ids,
                }
            );
        } catch (error) {
            dispatch(updateErrorState(error?.response?.data?.message));
            dispatch(setLoading(false));
        }
    };

    const updatePssQuestions = async (use_in_survey, is_social_sector) => {
        try {
            let url = `${Endpoints.updateAdditionalModules
                .replace(':survey_id', si.uuid)
                .replace(':question_sub_group_id', pssData?.uuid)}`;
            url = url.concat(is_social_sector ? `?is_social_included=${use_in_survey}` : `?include=${use_in_survey}`);
            return await api.put(url);
        } catch (error) {
            dispatch(updateErrorState(error?.response?.data?.message));
            dispatch(setSurveySliceLoading(false));
        }
    };

    const addRemoveModule = (item, index) => {
        const copy = cloneDeep(optionalModules);
        const { is_included, uuid: ques_sub_group_id, disable_questions_uuid } = item;
        copy[index].is_included = !is_included;
        setOptionalModules(copy);
        dispatch(UpdateAdditionalModules({ survey_id: si.uuid, ques_sub_group_id, is_include: !is_included }));

        if (disable_questions_uuid && is_included) {
            disable_questions_uuid.forEach(targetQuestionId => {
                let tabId = null;
                let secIndx = -1;
                let qIndex = -1;

                optionalQuesData.some(group => {
                    const foundSectionIndex = group.sections.findIndex(section => {
                        const foundQuestionIndex = section.questions.findIndex(
                            question => question.use_in_survey && question.uuid === targetQuestionId
                        );
                        if (foundQuestionIndex !== -1) {
                            tabId = group.id;
                            secIndx = group.sections.indexOf(section);
                            qIndex = foundQuestionIndex;
                            return true;
                        }
                        return false;
                    });
                    if (foundSectionIndex !== -1) {
                        secIndx = foundSectionIndex;
                        return true;
                    }
                    return false;
                });

                if (tabId !== null) {
                    dispatch(updateSectionQuestion({ tabId, secIndx, qIndex, checked: false }));
                    handleQuestionResponse(targetQuestionId, false);
                }
            });
        }
    };
    const addRemoveConfirm = (item, index) => {
        setReasonObj({ reason: '', item: item, index: index });
        setShowReasonModal(true);
    };
    const handleReasonSubmit = () => {
        setShowReasonModal(false);
        addRemoveModule(reasonObj.item, reasonObj.index);
        dispatch(
            removeReasonEmail({
                survey_id: si.uuid,
                reason: reasonObj.reason,
                section: reasonObj?.item?.sub_group_code.toUpperCase(),
            })
        );
    };
    React.useEffect(() => {
        dispatch(GetAdditionalModules({ survey_id: si.uuid, is_survey_setup: true }));
        if (isEmpty(ohiData) && !hideOutcomesAndPractices && showOutcomes) {
            dispatch(getOutcomesAndPractices({ surveyId: si?.uuid }));
        }
        return () => {
            dispatch(resetData());
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setOptionalModules(additionalModules);
    }, [additionalModules]);

    React.useEffect(() => {
        try {
            if (showOutcomes && !selectedQuestionCount) {
                setUnsaved(true);
            } else {
                setUnsaved(false);
                setErrorState(false);
            }
        } catch (err) {
            console.log(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQuestionCount]);

    const handleViewAll = item => {
        dispatch(GetAllQuesUnderModule({ survey_id: si.uuid, ques_sb_group_id: item.uuid }));
        openModal();
    };

    const close = React.useCallback(() => {
        dispatch(resetData());
    }, [dispatch]);

    const handleAddAll = async add => {
        try {
            dispatch(setLoading(true));
            const updatedOutcomesAndPractices = cloneDeep(ohiData);
            let totalQuestionAddedOrRemoved = 0;
            const question_ids = [];

            Object.entries(updatedOutcomesAndPractices).forEach(([, value]) => {
                const updatedQuestions = value.Outcome.questions;
                const updatedQuestionsLength = updatedQuestions.length;
                const selectedQuestionsLength = updatedQuestions.filter(({ use_in_survey }) => use_in_survey).length;
                const deSelectedQuestionsLength = updatedQuestionsLength - selectedQuestionsLength;

                totalQuestionAddedOrRemoved = add
                    ? totalQuestionAddedOrRemoved + deSelectedQuestionsLength
                    : totalQuestionAddedOrRemoved + selectedQuestionsLength;

                value.Outcome.use_in_survey = add;
                updatedQuestions.forEach(ques => {
                    const { use_in_survey, uuid } = ques;
                    ques.use_in_survey = add;
                    if (add && !use_in_survey) {
                        question_ids.push(uuid);
                    } else if (!add && use_in_survey) {
                        question_ids.push(uuid);
                    }
                });
            });

            const newCount = add
                ? selectedQuestionCount + totalQuestionAddedOrRemoved
                : selectedQuestionCount - totalQuestionAddedOrRemoved;

            const resp = await updateMultipleQuestions(question_ids, add);
            if (resp?.data) {
                dispatch(updateAllOutcomes({ data: updatedOutcomesAndPractices, count: newCount }));
            }
        } catch (err) {
            dispatch(setLoading(false));
        }
    };

    const handleAddClearPractices = async add => {
        try {
            dispatch(setLoading(true));
            const updatedOutcomesAndPractices = cloneDeep(ohiData);
            let totalQuestionAddedOrRemoved = 0;
            const question_ids = [];

            Object.entries(updatedOutcomesAndPractices).forEach(([, value]) => {
                const updatedSubtypes = value.Practices.subTypes;

                Object.entries(updatedSubtypes).forEach(([, value]) => {
                    const updatedQuestions = value.questions;
                    value.use_in_survey = add;
                    const updatedQuestionsLength = updatedQuestions.length;
                    const selectedQuestionsLength = updatedQuestions.filter(
                        ({ use_in_survey }) => use_in_survey
                    ).length;
                    const deSelectedQuestionsLength = updatedQuestionsLength - selectedQuestionsLength;

                    totalQuestionAddedOrRemoved = add
                        ? totalQuestionAddedOrRemoved + deSelectedQuestionsLength
                        : totalQuestionAddedOrRemoved + selectedQuestionsLength;

                    updatedQuestions.forEach(ques => {
                        const { use_in_survey, uuid } = ques;
                        ques.use_in_survey = add;
                        if (add && !use_in_survey) {
                            question_ids.push(uuid);
                        } else if (!add && use_in_survey) {
                            question_ids.push(uuid);
                        }
                    });
                });
            });

            const newCount = add
                ? selectedQuestionCount + totalQuestionAddedOrRemoved
                : selectedQuestionCount - totalQuestionAddedOrRemoved;

            const resp = await updateMultipleQuestions(question_ids, add);
            if (resp?.data) {
                dispatch(updateAllOutcomes({ data: updatedOutcomesAndPractices, count: newCount }));
            }
        } catch (err) {
            dispatch(setLoading(false));
        }
    };

    const handleReasonChange = value => {
        if (REGEX.spaceInBeginning.test(value)) {
            setReasonObj({ ...reasonObj, reason: value });
        }
    };

    return (
        <React.Fragment>
            {isHeaderRequired ? (
                <Header
                    position="fixed"
                    zIndex={999}
                    width="100%"
                    clickHandler={goToNext}
                    isNextEnabled={!errorState && !unsaved}
                />
            ) : null}
            {loading || ohiTempLoading ? <Loader showBackground position="fixed" background="transparent" /> : null}
            <EditSurveyDiv style={{ marginTop: !isHeaderRequired ? '-5vh' : '28vh' }}>
                <FlexColumnGap gap={pxToVh(40)}>
                    {tabs.length && showOutcomes && !hideOutcomesAndPractices ? (
                        <>
                            <OutcomeQuestions
                                styles={styles}
                                updateOutcomeQuestion={updateOutcomeQuestion}
                                outComeData={ohiData}
                                tabs={tabs}
                                isHeaderRequired={isHeaderRequired}
                                updateOutcome={updateOutcome}
                                updateAllOutcomes={updateAllOutcomes}
                                isPulse={isPulse}
                                handleQuestionResponse={handleQuestionResponse}
                                handleMultipleQuestionResponse={updateMultipleQuestions}
                                handleAddAll={handleAddAll}
                                setLoading={setLoading}
                            />
                            <PracticeSections
                                styles={styles}
                                updatePracticeQuestion={updatePracticeQuestion}
                                outComeData={ohiData}
                                updatePractice={updatePractice}
                                isPulse={isPulse}
                                handleQuestionResponse={handleQuestionResponse}
                                handleMultipleQuestionResponse={updateMultipleQuestions}
                                handleAddClearPractices={handleAddClearPractices}
                                setLoading={setLoading}
                                tabs={tabs}
                            />
                        </>
                    ) : null}
                    {otherThanValues.length ? (
                        <>
                            <Paper
                                elevation={2}
                                sx={{ ...styles, alignSelf: 'stretch' }}
                                className={c('outcomesAndPractices', {
                                    notPulse: true,
                                })}
                            >
                                {si?.schema_name == NPM_TEMPLATE ? (
                                    <PageHeader title={ADDITIONAL_TTILE} desc={ADDITIONAL_DESC} />
                                ) : null}
                                <Modulebody>
                                    <CommonLabel
                                        textWeight={500}
                                        textSize={18}
                                        lineHeight={28}
                                        label="OHI 4.0 sections"
                                        textColor={colorsCode.TextHeading}
                                    />
                                </Modulebody>
                                <ModuleWrapper>
                                    <CardWrapper gap={pxToVw(16)} flexWrap="wrap">
                                        {otherThanValues?.map((item, index) => {
                                            return (
                                                <OtherThanValuesCard
                                                    key={item.id}
                                                    isValues={false}
                                                    item={item}
                                                    index={index}
                                                    addRemoveModule={() => {
                                                        if (
                                                            item?.is_included &&
                                                            !isPulse &&
                                                            (item?.sub_group_code ===
                                                                additionalModulesConstant.workplace_flexibility ||
                                                                item?.sub_group_code ===
                                                                    additionalModulesConstant.employee_experience)
                                                        ) {
                                                            addRemoveConfirm(item, index);
                                                        } else if (
                                                            item?.is_included &&
                                                            item?.sub_group_code ===
                                                                additionalModulesConstant.workplace_flexibility
                                                        ) {
                                                            addRemoveConfirm(item, index);
                                                        } else {
                                                            addRemoveModule(item, index);
                                                        }
                                                    }}
                                                    desc={item.description?.en || item.group_text}
                                                    isSelected={!!item.view_only}
                                                />
                                            );
                                        })}
                                    </CardWrapper>
                                </ModuleWrapper>
                                <ModuleWrapper className="mainContent">
                                    <AccordionStyled paddingBottom={0.001} expanded={isExpanded}>
                                        <AccordionSummaryStyled
                                            pdLeftIcon={4}
                                            expandIcon={<img src={arrowDown} alt="arrow-down" />}
                                            pdTop={0}
                                            pdBottom={0}
                                            width={'fit-content'}
                                            onClick={() => {
                                                setIsExpanded(!isExpanded);
                                                if (isExpanded) {
                                                    setSelectedOtherValueTab(otherThanValuesTabs[0]);
                                                    setOtherThanValuesQuestion(otherThanValues[0]?.sections);
                                                }
                                            }}
                                        >
                                            <Typography className="actionClass" color={colorsCode.SecondaryDeepBlue500}>
                                                {isExpanded ? 'HIDE QUESTIONS' : 'VIEW QUESTIONS'}
                                            </Typography>
                                        </AccordionSummaryStyled>
                                        <AccordionDetailsStyled
                                            style={{
                                                paddingTop: 0,
                                                borderBottom: 0,
                                            }}
                                        >
                                            <TabsWrapper className="tabDiv">
                                                <FlexStartGap gap={pxToVw(16)}>
                                                    <FlexInline>
                                                        {otherThanValuesTabs?.map(tabName => (
                                                            <Pagetab
                                                                key={tabName}
                                                                active={selectedOtherValueTab === tabName}
                                                                label={tabName}
                                                                clickHandler={() => {
                                                                    setSelectedOtherValueTab(tabName);
                                                                    setOtherThanValuesQuestion(
                                                                        otherThanValues.find(
                                                                            item => item?.sub_group_type === tabName
                                                                        )?.sections
                                                                    );
                                                                }}
                                                            />
                                                        ))}
                                                    </FlexInline>
                                                </FlexStartGap>
                                            </TabsWrapper>
                                            <QuestionContent textSize={14} lineHeight={20}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        disabled
                                                        sx={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                disableRipple
                                                                checked={true}
                                                                sx={{
                                                                    height: 0,
                                                                    width: 0,
                                                                    padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                                    borderRadius: 0,
                                                                }}
                                                                icon={
                                                                    <img
                                                                        src={DisabledCheckboxSvg}
                                                                        alt=""
                                                                        className="img"
                                                                    />
                                                                }
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                className={`${stylesComp.formControlLabel} isDisabled`}
                                                                style={{ fontWeight: 500 }}
                                                            >
                                                                {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                                            </Typography>
                                                        }
                                                    />
                                                    {otherThanValuesQuestion?.map(
                                                        ({ question_text, use_in_survey, uuid }) => {
                                                            return (
                                                                <FormControlLabel
                                                                    disabled
                                                                    sx={{ margin: 0 }}
                                                                    key={uuid}
                                                                    control={
                                                                        <Checkbox
                                                                            disableRipple
                                                                            checked={use_in_survey}
                                                                            icon={
                                                                                <img
                                                                                    src={DisabledCheckboxSvg}
                                                                                    alt="checkbox-icon"
                                                                                    className="img"
                                                                                />
                                                                            }
                                                                            checkedIcon={<CheckBoxIcon />}
                                                                            sx={{
                                                                                height: 0,
                                                                                width: 0,
                                                                                padding: `${pxToVh(16)}vh ${pxToVh(
                                                                                    20
                                                                                )}vh`,
                                                                                borderRadius: 0,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Typography
                                                                            className={`${stylesComp.formControlLabel} isDisabled`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: question_text,
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </FormGroup>
                                            </QuestionContent>
                                        </AccordionDetailsStyled>
                                    </AccordionStyled>
                                </ModuleWrapper>
                            </Paper>
                        </>
                    ) : null}

                    {valuesData?.length || pssData?.uuid ? (
                        <OptionalModulesSection
                            isHeaderRequired={isHeaderRequired}
                            addRemoveModule={addRemoveModule}
                            styles={styles}
                            valuesData={valuesData}
                            pssData={pssData}
                            handleViewAll={handleViewAll}
                            updatePssQuestions={updatePssQuestions}
                            handleQuestionResponse={handleQuestionResponse}
                        />
                    ) : null}
                </FlexColumnGap>

                {optionalQuesData.length ? (
                    <OptionalQuestions
                        styles={styles}
                        optionalQuesData={optionalQuesData}
                        dependentSubGroupInfo={dependentSubGroupInfo}
                        handleQuestionResponse={handleQuestionResponse}
                        handleOtionalModuleResponse={handleOtionalModuleResponse}
                        handleMultipleQuestionResponse={handleMultipleQuestionResponse}
                        updateSectionQuestion={updateSectionQuestion}
                        updateTabQuestions={updateTabQuestions}
                    />
                ) : null}
            </EditSurveyDiv>
            {open && (
                <ViewAllModal
                    title={'Value questions'}
                    description="Includes questions regarding values and beliefs that underpin organizational performance. User must select values that describe client name."
                    list={valuesData[0]?.sections}
                    open={openModal}
                    close={closeModal}
                />
            )}
            {isSaved ? (
                <ShowMessage
                    type={error ? 'error' : 'success'}
                    message={error ? errorMessage : 'Additional module saved successfully'}
                    close={close}
                    open={isSaved}
                />
            ) : null}
            {showErrWarning ? (
                <ShowMessage
                    type={'error'}
                    message={MESSAGE.pulseError}
                    open={showErrWarning}
                    close={() => setShowErrWarning(false)}
                />
            ) : null}
            {showReasonModal ? (
                <Dialog
                    open={showReasonModal}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(620)}vw`,
                            width: `${pxToVw(620)}vw`,
                        },
                    }}
                >
                    <DialogTitle sx={{ mt: `${pxToVh(20)}vh`, ml: `${pxToVw(20)}vw` }}>
                        <FlexCenterWithAlign>
                            <CommonLabel
                                textSize={20}
                                textColor={colorsCode.TextHeading}
                                textWeight={500}
                                lineHeight={36}
                                label={`Skip adding ${reasonObj?.item?.sub_group_type} section`}
                            />
                        </FlexCenterWithAlign>
                        <FlexCenterWithAlign>
                            <Typography component="span" sx={{ fontSize: '12', marginTop: '20px' }} fontWeight="400">
                                {`Please provide a reason for skipping  ${reasonObj?.item?.sub_group_type}  section questions`}
                            </Typography>
                        </FlexCenterWithAlign>
                    </DialogTitle>

                    <DialogContent
                        sx={{
                            padding: `0 ${pxToVh(20)}vw`,
                        }}
                    >
                        <Icon
                            aria-label="close"
                            onClick={() => setShowReasonModal(false)}
                            sx={{
                                position: 'absolute',
                                right: `${pxToVw(16)}vw`,
                                top: `${pxToVh(16)}vh`,
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt=""
                                style={{
                                    height: `${pxToVh(24)}vh`,
                                    width: ` ${pxToVw(24)}vw`,
                                }}
                            />
                        </Icon>
                        <DialogContentText>
                            <InputField
                                ai="flex-start"
                                name="reason"
                                id="reason"
                                val={reasonObj.reason}
                                width={pxToVw(540)}
                                height={pxToVh(160)}
                                onChangeFn={e => {
                                    handleReasonChange(e?.target?.value);
                                }}
                                placeholder={LABELS.SURVEY_MODULE.OPEN_END_QUES.PREVIEW_TEXTBOX_PLACEHOLDER}
                                multiline
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            disabled={!reasonObj.reason.length}
                            onClick={() => setShowReasonModal(false)}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            disabled={!reasonObj.reason.length}
                            onClick={handleReasonSubmit}
                        >
                            Submit
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}
        </React.Fragment>
    );
};

export const Modulebody = styled.div`
    padding: ${pxToVh(40)}vh ${pxToVw(40)}vw ${pxToVh(20)}vh ${pxToVw(40)}vw;
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
`;

export default OptionalModules;
