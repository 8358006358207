import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {
    FlexStartGap,
    FlexInline,
    QuestionContent,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
} from '../../../../../../common/styled/styled';
import { Pagetab } from '../../../../../../common/FormInputs/Common';
import { SETUP_SURVEY_TEXT } from '../../../../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import { commonThemes } from '../../../../../../common/themes';
import CheckboxSvg from '../../../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../../../assets/img/DisabledCheckbox.svg';
import styled from 'styled-components';
import arrowDown from '../../../../../../assets/img/Small-down.svg';
import AuthStore from '../../../../../../common/AuthStore';

const PracticeQuestions = ({ subTypesData, handleParentCheck, outcome, handleColCheck, isPulse }) => {
    const stylesComp = commonThemes();

    const [selectedTab, setSelectedTab] = React.useState(null);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { SecondaryDeepBlue500 } = colorsCode;
    const tabs = Object.keys(subTypesData);
    const makeQuestionDisable = AuthStore.isAdmin ? !isPulse : !AuthStore.isAdmin;

    React.useEffect(() => {
        if (selectedTab === null || !isExpanded) {
            setSelectedTab(tabs[0]);
        }
        // eslint-disable-next-line
    }, [subTypesData, selectedTab, isExpanded]);

    const handleTab = newTabId => setSelectedTab(newTabId);

    const { selectedSubTypeObj: selectedSubType } = React.useMemo(() => {
        const selectedSubTypeObj = selectedTab ? subTypesData[selectedTab] : subTypesData[tabs[0]];
        return {
            selectedSubTypeObj,
        };
    }, [subTypesData, selectedTab]);

    const { questions = [] } = selectedSubType;
    const allQuesNotSelected = questions.some(({ use_in_survey }) => !use_in_survey);
    const selectedQuesLen = questions.filter(({ use_in_survey }) => use_in_survey).length;

    return (
        <div className="mainContent" style={{ width: '100%' }}>
            <AccordionStyled paddingBottom={0.001} expanded={isExpanded}>
                <AccordionSummaryStyled
                    pdLeftIcon={4}
                    expandIcon={<img src={arrowDown} alt="arrow-down" />}
                    pdTop={0}
                    pdBottom={0}
                    width={'fit-content'}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <Typography className="actionClass" color={SecondaryDeepBlue500}>
                        {isExpanded ? 'HIDE QUESTIONS' : 'VIEW QUESTIONS'}
                    </Typography>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled
                    style={{
                        paddingTop: 0,
                        borderBottom: 0,
                    }}
                >
                    <TabsWrapper className="tabDiv">
                        <FlexStartGap gap={pxToVw(16)}>
                            <FlexInline>
                                {tabs.map(tabName => (
                                    <Pagetab
                                        key={tabName}
                                        active={selectedTab === tabName}
                                        label={tabName}
                                        clickHandler={() => handleTab(tabName)}
                                    />
                                ))}
                            </FlexInline>
                        </FlexStartGap>
                    </TabsWrapper>
                    <QuestionContent textSize={14} lineHeight={20}>
                        <FormGroup>
                            <FormControlLabel
                                disabled={makeQuestionDisable}
                                sx={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        disableRipple
                                        checked={!allQuesNotSelected}
                                        indeterminate={!!(allQuesNotSelected && selectedQuesLen)}
                                        onChange={({ target }) =>
                                            handleParentCheck({
                                                outcome,
                                                subType: selectedTab,
                                                checked: target?.checked,
                                                questions,
                                            })
                                        }
                                        sx={{
                                            height: 0,
                                            width: 0,
                                            padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                            borderRadius: 0,
                                        }}
                                        icon={
                                            <img
                                                src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                alt=""
                                                className="img"
                                            />
                                        }
                                        checkedIcon={<CheckBoxIcon />}
                                    />
                                }
                                label={
                                    <Typography
                                        className={`${stylesComp.formControlLabel} ${
                                            makeQuestionDisable ? 'isDisabled' : ''
                                        }`}
                                        style={{ fontWeight: 500 }}
                                    >
                                        {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                    </Typography>
                                }
                            />
                            {questions.map(({ question_tex, use_in_survey, uuid }, qIndex) => {
                                return (
                                    <FormControlLabel
                                        disabled={makeQuestionDisable}
                                        sx={{ margin: 0 }}
                                        key={uuid}
                                        control={
                                            <Checkbox
                                                disableRipple
                                                checked={use_in_survey}
                                                onChange={({ target }) =>
                                                    handleColCheck(target, qIndex, outcome, uuid, selectedTab)
                                                }
                                                icon={
                                                    <img
                                                        src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                        alt="checkbox-icon"
                                                        className="img"
                                                    />
                                                }
                                                checkedIcon={<CheckBoxIcon />}
                                                sx={{
                                                    height: 0,
                                                    width: 0,
                                                    padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                    borderRadius: 0,
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                className={`${stylesComp.formControlLabel} ${
                                                    makeQuestionDisable ? 'isDisabled' : ''
                                                }`}
                                                dangerouslySetInnerHTML={{ __html: question_tex }}
                                            />
                                        }
                                    />
                                );
                            })}
                        </FormGroup>
                    </QuestionContent>
                </AccordionDetailsStyled>
            </AccordionStyled>
        </div>
    );
};

export default PracticeQuestions;

export const TabsWrapper = styled.div`
    padding: 20px 0 0 0;
    align-self: stretch;
`;
