import React from 'react';
import Typography from '@mui/material/Typography';
import { FlexBetween, FlexCenterWithAlign } from '../../../../../../common/styled/styled';
import { Modulebody } from '../../OptionalModules';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import { colorsCode, pxToVw } from '../../../../../../common/colors';

const AddAllClear = ({ isPulse, handleAddAll, category }) => {
    const { SecondaryDeepBlue500, Neutral30 } = colorsCode;

    return (
        <Modulebody>
            <FlexBetween>
                <CommonLabel textWeight={500} textSize={18} lineHeight={28} label={category} />
                {isPulse ? (
                    <FlexCenterWithAlign gap={pxToVw(16)}>
                        <Typography
                            className="actionClass"
                            color={SecondaryDeepBlue500}
                            onClick={() => {
                                handleAddAll(true);
                            }}
                        >
                            ADD ALL
                        </Typography>
                        <Typography
                            className="actionClass"
                            color={SecondaryDeepBlue500}
                            onClick={() => {
                                handleAddAll(false);
                            }}
                        >
                            CLEAR
                        </Typography>
                    </FlexCenterWithAlign>
                ) : (
                    <CommonLabel
                        textWeight={400}
                        textSize={14}
                        lineHeight={20}
                        label="Included by default"
                        textColor={Neutral30}
                    />
                )}
            </FlexBetween>
        </Modulebody>
    );
};

export default AddAllClear;
