import React from 'react';
import * as sc from '../../../../../../common/styled/styled';
import * as c from '../../../../../../common/colors';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import Checked from '../../../../../../assets/img/checked.svg';
import DisableChecked from '../../../../../../assets/img/disableChecked.svg';
import Unchecked from '../../../../../../assets/img/uncheck.svg';
import LightTooltip from '../../../../../../common/LightTooltip';
import styled from 'styled-components';
import { LABELS } from '../../../../../../global-constants';

const OutcomePracticeCard = ({ item, index, addRemoveModule, isPulse, isOutcome }) => {
    const { cyan100, Neutral10, Neutral30, whiteShade, primaryCyan, TextHeading, backgroundDisabled, Neutral05 } =
        c.colorsCode;
    const { ADD_OUTCOME, REMOVE_OUTCOME, ADD_PRACTICE, REMOVE_PRACTICE } = LABELS.SURVEY_MODULE.OPTIONAL_MODULES;
    const { use_in_survey, name } = item;

    const getCardColor = () => {
        let bgColor;
        let borderColor;
        if (isPulse) {
            if (use_in_survey) {
                bgColor = cyan100;
                borderColor = primaryCyan;
            } else {
                bgColor = whiteShade;
                borderColor = Neutral10;
            }
        } else {
            bgColor = Neutral05;
            borderColor = backgroundDisabled;
        }
        return { bgColor, borderColor };
    };
    const { bgColor, borderColor } = getCardColor();

    const getTooltip = () => {
        let tooltipText = '';
        if (isOutcome) {
            if (use_in_survey) {
                tooltipText = REMOVE_OUTCOME;
            } else {
                tooltipText = ADD_OUTCOME;
            }
        } else {
            if (use_in_survey) {
                tooltipText = REMOVE_PRACTICE;
            } else {
                tooltipText = ADD_PRACTICE;
            }
        }
        return tooltipText;
    };

    return (
        <CardWrapper key={name} background={bgColor} borderColor={borderColor}>
            <sc.FlexBetween>
                <CommonLabel
                    label={name}
                    textColor={isPulse ? TextHeading : Neutral30}
                    textWeight={500}
                    textSize={18}
                    lineHeight={28}
                />
                <LightTooltip title={isPulse ? getTooltip() : null} placement="top" arrow>
                    <FormControlLabel
                        sx={{ margin: '0' }}
                        control={
                            <Checkbox
                                disabled={!isPulse}
                                value={name}
                                checked={use_in_survey}
                                icon={
                                    <img
                                        src={Unchecked}
                                        alt=""
                                        style={{ height: `${c.pxToVh(24)}vh`, width: `${c.pxToVw(24)}vw` }}
                                    />
                                }
                                checkedIcon={
                                    <img
                                        src={isPulse ? Checked : DisableChecked}
                                        alt=""
                                        style={{ height: `${c.pxToVh(24)}vh`, width: `${c.pxToVw(24)}vw` }}
                                    />
                                }
                                onClick={() => addRemoveModule(item, index)}
                                sx={{
                                    height: 0,
                                    width: 0,
                                }}
                            />
                        }
                    />
                </LightTooltip>
            </sc.FlexBetween>
        </CardWrapper>
    );
};

export const CardWrapper = styled.div`
    width: 32%;
    padding: ${c.pxToVh(20)}vh ${c.pxToVw(24)}vw;
    border-radius: 8px;
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    background: ${({ background }) => background};
`;

export default OutcomePracticeCard;
