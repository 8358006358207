import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import {
    AccordionStyled,
    AccordionSummaryStyled,
    AccordionDetailsStyled,
    Flex,
    FlexColumnWithAlign,
} from '../../../../../../common/styled/styled';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import { colorsCode, pxToVw, pxToVh } from '../../../../../../common/colors';
import OutcomePracticeCard from './OutcomePracticeCard';
import styled from 'styled-components';
import c from 'classnames';
import PracticeQuestions from './PracticeQuestions';
import AddAllClear from './AddAllClearComponent';
import Typography from '@mui/material/Typography';
import arrowDown from '../../../../../../assets/img/Small-down.svg';
import AuthStore from '../../../../../../common/AuthStore';

const PracticeSections = ({
    styles,
    outComeData,
    updatePracticeQuestion,
    updatePractice,
    isPulse,
    handleQuestionResponse,
    handleMultipleQuestionResponse,
    handleAddClearPractices,
    setLoading,
    tabs,
}) => {
    const dispatch = useDispatch();
    const [showAll, setShowAll] = React.useState(false);

    const { TextSubtle, SecondaryDeepBlue500 } = colorsCode;

    const handleParentCheck = async ({ outcome, subType, checked, questions }) => {
        dispatch(setLoading(true));
        const question_ids = questions.map(({ uuid }) => uuid);
        const resp = await handleMultipleQuestionResponse(question_ids, checked);
        if (resp) {
            await dispatch(updatePractice({ outcome, subType, checked }));
        }
    };

    const handleColCheck = async ({ checked }, qIndex, outcome, questionId, subType) => {
        dispatch(setLoading(true));
        const resp = await handleQuestionResponse(questionId, checked);
        if (resp) {
            dispatch(
                updatePracticeQuestion({
                    outcome,
                    subType,
                    checked,
                    qIndex,
                })
            );
        }
    };

    return (
        <Paper
            elevation={2}
            sx={{ ...styles, alignSelf: 'stretch' }}
            className={c('outcomesAndPractices', {
                notPulse: AuthStore.isAdmin ? isPulse === false : !AuthStore.isAdmin,
            })}
        >
            <AddAllClear handleAddAll={handleAddClearPractices} isPulse={isPulse} category={'Practices'} />
            <SubtypesContainer gap={pxToVh(40)}>
                {tabs.slice(0, 2).map(outcome => {
                    const outcomeObj = outComeData[outcome];
                    const { subTypes } = outcomeObj.Practices;
                    return (
                        <SubTypeWrapper gap={pxToVh(20)} key={outcome}>
                            <CommonLabel
                                textWeight={400}
                                textSize={18}
                                lineHeight={28}
                                label={outcome}
                                textColor={TextSubtle}
                            />
                            <CardWrapper gap={pxToVw(20)} flexWrap="wrap">
                                {Object.entries(subTypes).map(([subType, subTypeObj], subTypeIndex) => {
                                    const { use_in_survey, questions } = subTypeObj;

                                    return (
                                        <OutcomePracticeCard
                                            key={subType}
                                            item={subTypeObj}
                                            index={subTypeIndex}
                                            addRemoveModule={() => {
                                                handleParentCheck({
                                                    outcome,
                                                    subType,
                                                    checked: !use_in_survey,
                                                    questions,
                                                });
                                            }}
                                            isPulse={isPulse}
                                        />
                                    );
                                })}
                            </CardWrapper>
                            <PracticeQuestions
                                subTypesData={subTypes}
                                outcome={outcome}
                                handleColCheck={handleColCheck}
                                handleParentCheck={handleParentCheck}
                                isPulse={isPulse}
                            />
                        </SubTypeWrapper>
                    );
                })}
            </SubtypesContainer>
            <div className="mainContent">
                <AccordionStyled paddingBottom={0.001} expanded={showAll}>
                    <AccordionSummaryStyled
                        pdLeftIcon={4}
                        expandIcon={<img src={arrowDown} alt="arrow-down" />}
                        pdTop={40}
                        pdBottom={40}
                        pdLeft={20}
                        width={'fit-content'}
                        onClick={() => {
                            setShowAll(!showAll);
                        }}
                    >
                        <Typography className="actionClass" color={SecondaryDeepBlue500}>
                            {`${showAll ? 'HIDE' : 'VIEW'} ALL PRACTICES`}
                        </Typography>
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled
                        style={{
                            paddingTop: 0,
                            borderBottom: 0,
                        }}
                    >
                        <SubtypesContainer gap={pxToVh(40)}>
                            {tabs.slice(2, tabs.length).map(outcome => {
                                const outcomeObj = outComeData[outcome];
                                const { subTypes } = outcomeObj.Practices;
                                return (
                                    <SubTypeWrapper gap={pxToVh(20)} key={outcome}>
                                        <CommonLabel
                                            textWeight={400}
                                            textSize={18}
                                            lineHeight={28}
                                            label={outcome}
                                            textColor={TextSubtle}
                                        />
                                        <CardWrapper gap={pxToVw(20)} flexWrap="wrap">
                                            {Object.entries(subTypes).map(([subType, subTypeObj], subTypeIndex) => {
                                                const { use_in_survey, questions } = subTypeObj;

                                                return (
                                                    <OutcomePracticeCard
                                                        key={subType}
                                                        item={subTypeObj}
                                                        index={subTypeIndex}
                                                        addRemoveModule={() => {
                                                            handleParentCheck({
                                                                outcome,
                                                                subType,
                                                                checked: !use_in_survey,
                                                                questions,
                                                            });
                                                        }}
                                                        isPulse={isPulse}
                                                    />
                                                );
                                            })}
                                        </CardWrapper>
                                        <PracticeQuestions
                                            subTypesData={subTypes}
                                            outcome={outcome}
                                            handleColCheck={handleColCheck}
                                            handleParentCheck={handleParentCheck}
                                            isPulse={isPulse}
                                        />
                                    </SubTypeWrapper>
                                );
                            })}
                        </SubtypesContainer>
                    </AccordionDetailsStyled>
                </AccordionStyled>
            </div>
        </Paper>
    );
};

export default PracticeSections;

export const SubtypesContainer = styled(Flex)`
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    gap: ${({ gap }) => gap}vh;
    margin: ${({ margin }) => margin};
    padding-top: 0;
    padding-bottom: 0;

}`;

export const SubTypeWrapper = styled(FlexColumnWithAlign)`
    border-bottom: 1px solid ${colorsCode.Neutral10};
    width: 100%;
    padding: 0 ${pxToVh(20)}vw ${pxToVh(40)}vh ${pxToVh(20)}vw;
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: ${({ flexWrap }) => flexWrap};
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
    width: 100%;
    padding-bottom: ${pxToVh(20)}vh;
`;
