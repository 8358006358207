export const additionalModulesConstant = {
    is_values: 'is_values',
    is_values_2: 'is_values_2',
    employee_experience: 'employee_experience',
    workplace_flexibility: 'workplace_flexibility',
    employee_experience_optional: 'employee_experience_optional',
    employee_journey_optional: 'employee_journey_optional',
    workplace_flexibility_optional: 'workplace_flexibility_optional',
    practice_questions_optional: 'practice_questions_optional',
    three_point_two_questions_optional: '3.2_questions_optional',
    four_point_zero_questions_optional: '4_0_questions_optional',
    resurvey_questions_optional: 'resurvey_questions_optional',
};

export const primaryTabs = {
    templateSection: 'templateSection',
    optionalModules: 'optionalModules',
    optionalQuestions: 'optionalQuestions',
};

export const subPrimaryTabs = {
    values_module: 'values_module',
};

export const labelsForAdditionalSections = {
    is_values: 'These questions asks employees to select values that describe their employers.',
    is_values_2: 'These questions asks employees to select values that describe their employers.',
    employee_experience:
        "This section aims to measure various aspects of an employee's journey within the organization through scale-based and multi-select questions.",
    workplace_flexibility:
        'This section aims to understand employee work arrangements and preferences through single-select questions.',
    employee_experience_optional:
        "This section aims to measure various aspects of an employee's journey within the organization through scale-based and multi-select questions.",
    employee_journey_optional:
        "This section aims to understand the employees' journey at each stage from recruitment to handling major life events through scale-based questions.",
    workplace_flexibility_optional:
        'This section aims to understand employee work arrangements and preferences through single-select questions.',
    '3.2_questions_optional':
        'This section comprises of scale-based questions covering various aspects such as strategic clarity, consequence management, financial management, and more.',
    practice_questions_optional:
        "This section evaluates the organization's practices regarding external partners' conduct, internal job opportunities, skills development, and knowledge sharing among employees through scale-based questions.",
    '4_0_questions_optional':
        'This section comprises of scale-based questions covering various practices related to OHI 4.0.',
};
