import { ButtonGroup, Button } from '@mui/material';
import React from 'react';
import { colorsCode } from './colors';
import { CommonLabel } from './FormInputs/Common';
import { commonThemes } from './themes';

export const ButtonView = ({ viewType, setType, btn1text, btn2Text }) => {
    const styles = commonThemes();
    const activeCardClass = viewType === 1 ? `${styles.buttonActive}` : `${styles.buttonBorderInact}`;
    const activeTableClass = viewType === 2 ? `${styles.buttonActive}` : `${styles.buttonBorderInact}`;

    const handleClick = val => {
        setType(val);
    };

    return (
        <ButtonGroup data-testid="buttongroup">
            <Button
                variant="outlined"
                size="small"
                className={`${styles.buttonGroup} ${activeCardClass}`}
                onClick={() => handleClick(1)}
                data-testid="card"
            >
                <CommonLabel
                    textWeight={400}
                    textSize={14}
                    textColor={viewType === 1 ? colorsCode.Neutral80 : colorsCode.Neutral30}
                    lineHeight={20}
                    label={btn1text}
                    cursor="pointer"
                />
            </Button>
            <Button
                variant="outlined"
                size="small"
                className={`${styles.buttonGroup} ${activeTableClass}`}
                onClick={() => handleClick(2)}
                data-testid="table"
            >
                <CommonLabel
                    textWeight={400}
                    textSize={14}
                    textColor={viewType === 2 ? colorsCode.Neutral80 : colorsCode.Neutral30}
                    lineHeight={20}
                    label={btn2Text}
                    cursor="pointer"
                />
            </Button>
        </ButtonGroup>
    );
};
