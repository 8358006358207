import React from 'react';
import * as sc from '../../../../../common/styled/styled';
import * as c from '../../../../../common/colors';
import { Checkbox, checkboxClasses, FormControlLabel } from '@mui/material';
import { CommonLabel, Img } from '../../../../../common/FormInputs/Common';
import Checked from '../../../../../assets/img/checked.svg';
import Unchecked from '../../../../../assets/img/uncheck.svg';
import LightTooltip from '../../../../../common/LightTooltip';
import styled from 'styled-components';
import { LABELS, NPM_TEMPLATE } from '../../../../../global-constants';
import QuestionMark from '../../../../../assets/img/C-question.svg';
import { useSelector } from 'react-redux';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import CheckboxSvg from '../../../../../assets/img/Checkbox.svg';
import InfoIcon from '@mui/icons-material/Info';
import DisabledCheckboxSvg from '../../../../../assets/img/DisabledCheckbox.svg';

const ModuleCard = ({ item, index, addRemoveModule, isValues, handleViewAll, btnlabel, handleParentCheck }) => {
    const { Neutral80, Neutral70, cyan100, Neutral10, whiteShade, primaryCyan, TextHeading } = c.colorsCode;
    const { ADD_MODULE, REMOVE_MODULE } = LABELS.SURVEY_MODULE.OPTIONAL_MODULES;
    const { ONLY_ENGLISH_AVAILABLE } = LABELS;
    const { is_included, group_text, id, total_additional_question, is_social_included, total_questions } = item;
    const { surveyInformation } = useSelector(state => state?.surveyReducer);

    const getTooltip = () => {
        return is_included ? REMOVE_MODULE : ADD_MODULE;
    };
    return (
        <OptionalModuleCard
            key={id}
            background={is_included ? cyan100 : whiteShade}
            borderColor={is_included ? primaryCyan : Neutral10}
        >
            <sc.FlexColumnBetween
                gap={c.pxToVh(20)}
                style={{ flexShrink: 0, alignItems: 'flex-start', height: '100%' }}
            >
                <sc.FlexColumnGap gap={c.pxToVh(8)} style={{ flex: 1 }}>
                    <sc.FlexBetween style={{ alignSelf: 'stretch' }}>
                        <CommonLabel
                            label={group_text}
                            textColor={TextHeading}
                            textWeight={500}
                            textSize={18}
                            lineHeight={28}
                        />
                        <LightTooltip title={getTooltip()} placement="top" arrow>
                            {surveyInformation?.schema_name !== NPM_TEMPLATE && (
                                <FormControlLabel
                                    sx={{
                                        margin: '0',
                                        alignSelf: 'flex-start',
                                    }}
                                    control={
                                        <Checkbox
                                            value={group_text}
                                            checked={is_included}
                                            icon={
                                                <img
                                                    src={Unchecked}
                                                    alt=""
                                                    style={{
                                                        height: `${c.pxToVh(24)}vh`,
                                                        width: `${c.pxToVw(24)}vw`,
                                                    }}
                                                />
                                            }
                                            checkedIcon={
                                                <img
                                                    src={Checked}
                                                    alt=""
                                                    style={{
                                                        height: `${c.pxToVh(24)}vh`,
                                                        width: `${c.pxToVw(24)}vw`,
                                                    }}
                                                />
                                            }
                                            onChange={({ target }) => {
                                                isValues ? addRemoveModule(item, index) : handleParentCheck(target);
                                            }}
                                            sx={{
                                                height: 0,
                                                width: 0,
                                            }}
                                        />
                                    }
                                />
                            )}
                        </LightTooltip>
                    </sc.FlexBetween>
                    {total_additional_question ? (
                        <CommonLabel
                            label={ONLY_ENGLISH_AVAILABLE}
                            textSize={12}
                            textWeight={400}
                            textColor={Neutral80}
                            lineHeight={16}
                            title={ONLY_ENGLISH_AVAILABLE}
                        />
                    ) : null}
                    <CommonLabel
                        label={item?.self_serve_description}
                        textSize={16}
                        textWeight={400}
                        textColor={Neutral80}
                        lineHeight={24}
                        title={item?.self_serve_description}
                        enableEllipsis={true}
                        customStyles={{ WebkitLineClamp: '3' }}
                    />
                </sc.FlexColumnGap>
                <sc.FlexColumnGap gap={c.pxToVh(20)}>
                    <sc.FlexStartGap gap={c.pxToVw(8)} style={{ alignItems: 'center' }}>
                        <Img image={QuestionMark} h={c.pxToVh(16)} w={c.pxToVw(16)} alt="" />
                        <CommonLabel
                            textColor={Neutral70}
                            textWeight={300}
                            label={isValues ? '2 Questions' : `${total_questions} Questions`}
                            textSize={14}
                            lineHeight={20}
                        />
                    </sc.FlexStartGap>

                    {total_additional_question ? (
                        <sc.FlexStartGap gap={pxToVw(0)} style={{ alignItems: 'center' }}>
                            <FormControlLabel
                                sx={{
                                    [`&, &.${checkboxClasses.root}`]: {
                                        'margin-right': '0.3rem',
                                    },
                                }}
                                disabled={!item.is_included}
                                control={
                                    <Checkbox
                                        checkedIcon={
                                            <CheckBoxIcon
                                                sx={{
                                                    color: '#000',
                                                    width: `${pxToVw(23)}vw`,
                                                    height: `${pxToVh(23)}vh`,
                                                }}
                                            />
                                        }
                                        checked={is_social_included}
                                        name="is_social_sector"
                                        onChange={({ target }) => {
                                            handleParentCheck(target, true);
                                        }}
                                        inputProps={{
                                            'data-testid': 'is_social_sector',
                                        }}
                                        sx={{
                                            height: 0,
                                            width: 0,
                                            margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                        }}
                                        icon={
                                            <img
                                                alt="question-checkbox"
                                                src={!item.is_included ? DisabledCheckboxSvg : CheckboxSvg}
                                                className="img"
                                                style={{
                                                    width: `${pxToVw(18)}vw`,
                                                    height: `${pxToVh(18)}vh`,
                                                }}
                                            />
                                        }
                                    />
                                }
                                label={
                                    <CommonLabel
                                        textSize={16}
                                        textWeight={400}
                                        textColor={colorsCode.Neutral80}
                                        label="Add Social-sector only practices"
                                        lineHeight={24}
                                    />
                                }
                            />
                            <LightTooltip title={item?.info_icon_description} placement="top" arrow>
                                <InfoIcon
                                    sx={{
                                        color: colorsCode.Neutral30,
                                        width: '16px',
                                        height: '16px',
                                    }}
                                />
                            </LightTooltip>
                        </sc.FlexStartGap>
                    ) : null}

                    {total_additional_question ? (
                        <sc.FlexStartGap gap={c.pxToVw(8)} style={{ alignItems: 'center' }}>
                            <Img image={QuestionMark} h={c.pxToVh(16)} w={c.pxToVw(16)} alt="" />
                            <CommonLabel
                                textColor={Neutral70}
                                textWeight={300}
                                label={`${total_additional_question} additional questions`}
                                textSize={14}
                                lineHeight={20}
                            />
                        </sc.FlexStartGap>
                    ) : null}

                    {btnlabel ? (
                        <sc.CTA
                            bg={is_included ? cyan100 : whiteShade}
                            bcolor={primaryCyan}
                            fw={400}
                            lh={20}
                            size={14}
                            onClick={handleViewAll}
                        >
                            {btnlabel}
                        </sc.CTA>
                    ) : null}
                </sc.FlexColumnGap>
            </sc.FlexColumnBetween>
        </OptionalModuleCard>
    );
};

const OptionalModuleCard = styled.div`
    width: ${c.pxToVw(390)}vw;
    height: ${c.pxToVh(292)}vh;
    padding: 1.2rem;
    border-radius: 8px;
    border: 1px solid ${props => props.borderColor || c.colorsCode.Neutral10};
    background: ${props => props.background || c.colorsCode.whiteShade};
`;

export default ModuleCard;
