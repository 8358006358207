import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {
    FlexStartGap,
    FlexInline,
    QuestionContent,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
} from '../../../../../../common/styled/styled';
import { Pagetab } from '../../../../../../common/FormInputs/Common';
import { SETUP_SURVEY_TEXT } from '../../../../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import { commonThemes } from '../../../../../../common/themes';
import CheckboxSvg from '../../../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../../../assets/img/DisabledCheckbox.svg';
import OutcomePracticeCard from './OutcomePracticeCard';
import styled from 'styled-components';
import PageHeader from '../../common-components/PageHeader';
import { LABELS } from '../../../../../../global-constants';
import c from 'classnames';
import AddAllClear from './AddAllClearComponent';
import arrowDown from '../../../../../../assets/img/Small-down.svg';
import AuthStore from '../../../../../../common/AuthStore';
const { ADDITIONAL_TTILE, ADDITIONAL_DESC } = LABELS.SURVEY_MODULE.OPTIONAL_MODULES;

const OutcomeQuestions = ({
    styles,
    outComeData,
    isHeaderRequired,
    tabs,
    updateOutcomeQuestion,
    updateOutcome,
    isPulse,
    handleQuestionResponse,
    handleMultipleQuestionResponse,
    handleAddAll,
    setLoading,
}) => {
    const dispatch = useDispatch();
    const stylesComp = commonThemes();
    const [selectedTab, setSelectedTab] = React.useState(null);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { SecondaryDeepBlue500 } = colorsCode;

    React.useEffect(() => {
        if (selectedTab === null || !isExpanded) {
            setSelectedTab(tabs[0]);
        }
        // eslint-disable-next-line
    }, [outComeData, selectedTab, isExpanded]);

    const handleTab = newTabId => setSelectedTab(newTabId);

    const { selectedOutcomeObj: selectedOutcome } = React.useMemo(() => {
        const selectedOutcomeObj = selectedTab ? outComeData[selectedTab] : outComeData[tabs[0]];
        return {
            selectedOutcomeObj,
        };
    }, [outComeData, selectedTab, tabs]);

    const handleParentCheck = async ({ checked, tab, questions }) => {
        dispatch(setLoading(true));
        const question_ids = questions.map(({ uuid }) => uuid);
        const resp = await handleMultipleQuestionResponse(question_ids, checked);
        if (resp) {
            await dispatch(updateOutcome({ selectedTab: tab, checked }));
        }
    };

    const handleColCheck = async ({ checked }, qIndex, questionId) => {
        dispatch(setLoading(true));
        const resp = await handleQuestionResponse(questionId, checked);
        if (resp) {
            dispatch(updateOutcomeQuestion({ qIndex, checked, selectedTab }));
        }
    };

    const {
        Outcome: { questions = [] },
    } = selectedOutcome;
    const allQuesNotSelected = questions.some(({ use_in_survey }) => !use_in_survey);
    const selectedQuesLen = questions.filter(({ use_in_survey }) => use_in_survey).length;
    const makeQuestionDisable = AuthStore.isAdmin ? !isPulse : !AuthStore.isAdmin;

    return (
        <Paper
            elevation={2}
            sx={{ ...styles, alignSelf: 'stretch' }}
            className={c('outcomesAndPractices', {
                notPulse: AuthStore.isAdmin ? isPulse === false : !AuthStore.isAdmin,
            })}
        >
            {isHeaderRequired ? <PageHeader title={ADDITIONAL_TTILE} desc={ADDITIONAL_DESC} /> : null}
            <AddAllClear handleAddAll={handleAddAll} isPulse={isPulse} category={'Outcomes'} />
            <ModuleWrapper>
                <CardWrapper gap={pxToVw(16)} flexWrap="wrap">
                    {Object.entries(outComeData).map(([key, value], index) => {
                        const outcomeObj = value.Outcome;
                        const { use_in_survey, questions } = outcomeObj;
                        return (
                            <OutcomePracticeCard
                                key={key}
                                item={outcomeObj}
                                name={key}
                                index={index}
                                addRemoveModule={() => {
                                    handleParentCheck({ tab: key, checked: !use_in_survey, questions });
                                }}
                                isPulse={isPulse}
                                isOutcome
                            />
                        );
                    })}
                </CardWrapper>
            </ModuleWrapper>

            <ModuleWrapper className="mainContent">
                <AccordionStyled paddingBottom={0.001} expanded={isExpanded}>
                    <AccordionSummaryStyled
                        pdLeftIcon={4}
                        expandIcon={<img src={arrowDown} alt="arrow-down" />}
                        pdTop={0}
                        pdBottom={0}
                        width={'fit-content'}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <Typography className="actionClass" color={SecondaryDeepBlue500}>
                            {isExpanded ? 'HIDE QUESTIONS' : 'VIEW QUESTIONS'}
                        </Typography>
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled
                        style={{
                            paddingTop: 0,
                            borderBottom: 0,
                        }}
                    >
                        <TabsWrapper className="tabDiv">
                            <FlexStartGap gap={pxToVw(16)}>
                                <FlexInline>
                                    {tabs.map(tabName => (
                                        <Pagetab
                                            key={tabName}
                                            active={selectedTab === tabName}
                                            label={tabName}
                                            clickHandler={() => handleTab(tabName)}
                                        />
                                    ))}
                                </FlexInline>
                            </FlexStartGap>
                        </TabsWrapper>
                        <QuestionContent textSize={14} lineHeight={20}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={makeQuestionDisable}
                                    sx={{ margin: 0 }}
                                    control={
                                        <Checkbox
                                            disableRipple
                                            checked={!allQuesNotSelected}
                                            indeterminate={!!(allQuesNotSelected && selectedQuesLen)}
                                            onChange={({ target }) =>
                                                handleParentCheck({
                                                    tab: selectedTab,
                                                    checked: target?.checked,
                                                    questions,
                                                })
                                            }
                                            sx={{
                                                height: 0,
                                                width: 0,
                                                padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                borderRadius: 0,
                                            }}
                                            icon={
                                                <img
                                                    src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                    alt=""
                                                    className="img"
                                                />
                                            }
                                            checkedIcon={<CheckBoxIcon />}
                                        />
                                    }
                                    label={
                                        <Typography
                                            className={`${stylesComp.formControlLabel} ${
                                                makeQuestionDisable ? 'isDisabled' : ''
                                            }`}
                                            style={{ fontWeight: 500 }}
                                        >
                                            {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                        </Typography>
                                    }
                                />
                                {questions.map(({ question_tex, use_in_survey, uuid }, qIndex) => {
                                    return (
                                        <FormControlLabel
                                            disabled={makeQuestionDisable}
                                            sx={{ margin: 0 }}
                                            key={uuid}
                                            control={
                                                <Checkbox
                                                    disableRipple
                                                    checked={use_in_survey}
                                                    onChange={({ target }) => handleColCheck(target, qIndex, uuid)}
                                                    icon={
                                                        <img
                                                            src={
                                                                makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg
                                                            }
                                                            alt="checkbox-icon"
                                                            className="img"
                                                        />
                                                    }
                                                    checkedIcon={<CheckBoxIcon />}
                                                    sx={{
                                                        height: 0,
                                                        width: 0,
                                                        padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                        borderRadius: 0,
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    className={`${stylesComp.formControlLabel} ${
                                                        makeQuestionDisable ? 'isDisabled' : ''
                                                    }`}
                                                    dangerouslySetInnerHTML={{ __html: question_tex }}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </FormGroup>
                        </QuestionContent>
                    </AccordionDetailsStyled>
                </AccordionStyled>
            </ModuleWrapper>
        </Paper>
    );
};

export default OutcomeQuestions;

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: ${({ flexWrap }) => flexWrap};
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
`;

export const ModuleWrapper = styled.div`
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
    padding: 0 ${pxToVw(40)}vw ${pxToVh(40)}vh ${pxToVw(40)}vw;
`;

export const TabsWrapper = styled.div`
    padding: 20px 0 0 0;
    align-self: stretch;
`;
