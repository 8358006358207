import { Checkbox, Paper, Table, TableBody, TableHead, TableRow, Select, MenuItem, Menu } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import { FlexBetween, FlexStartGap, TableCellStyled } from '../../../../../../common/styled/styled';
import { QUES_STATUS_TYPE, QUES_TYPE_COLL } from '../../../../../../global-constants';
import EditIcon from '../../../../../../assets/img/edit_blue.svg';
import ArrowDownIcon from '../../../../../../assets/img/arrow_down.svg';
import doc from '../../../../../../assets/img/document.svg';
import plus from '../../../../../../assets/img/add-icon.svg';
import CheckboxSvg from '../../../../../../assets/img/Checkbox.svg';
import { useDispatch } from 'react-redux';
import { setCustomTab } from '../../../../../../store/reducers/slice/Demographics';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StatusBadge } from '../../Badge';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const QuestionTable = ({
    state,
    AuthStore,
    handleOneClick,
    showImageStatus,
    handleStatus,
    images,
    handleReason,
    goToTab,
    handleAllClick,
    bg,
}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(false);
    const [optionsData, setOptionsData] = useState([]);
    const open = Boolean(anchorEl);
    const selectedLength = state?.filter(({ is_selected }) => is_selected === true)?.length;
    const styleObj = {
        width: `${pxToVw(23)}vw`,
        height: `${pxToVh(23)}vh`,
        color: colorsCode.TextHeading,
    };
    const checkBoxStyle = {
        width: `${pxToVw(18)}vw`,
        height: `${pxToVh(18)}vh`,
    };
    const checkBoxHeight = {
        height: 0,
        width: 0,
    };

    const getSectionLabel = ({ is_custom, is_demo, is_opentext }) => {
        if (is_opentext) {
            return 'Open End Questions';
        }
        if (is_demo) {
            return 'Demographic Questions';
        }
        if (is_custom) {
            return 'Additional Questions';
        }
    };

    const handleMenuClick = (e, i) => {
        setAnchorEl(e.currentTarget);
        setOptionsData(state[i].options);
    };

    return (
        <Fragment>
            <Table component={Paper} sx={{ boxShadow: colorsCode.boxShadow }}>
                <TableHead>
                    <TableRow>
                        {AuthStore.isAdminOrCoach && (
                            <TableCellStyled sx={{ width: '30px' }}>
                                <Checkbox
                                    onClick={handleAllClick}
                                    sx={checkBoxHeight}
                                    indeterminate={selectedLength > 0 && selectedLength !== state.length}
                                    checked={
                                        selectedLength > 0 && state?.every(({ is_selected }) => is_selected === true)
                                    }
                                    icon={<img src={CheckboxSvg} className="img" style={checkBoxStyle} />}
                                    indeterminateIcon={<IndeterminateCheckBoxIcon sx={styleObj} />}
                                    checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                />
                            </TableCellStyled>
                        )}
                        <TableCellStyled>Questions</TableCellStyled>
                        <TableCellStyled>Sections</TableCellStyled>
                        <TableCellStyled>Scale type</TableCellStyled>
                        <TableCellStyled>Status</TableCellStyled>
                        <TableCellStyled>Reasons</TableCellStyled>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.length ? (
                        state.map(
                            (
                                {
                                    is_selected,
                                    is_custom,
                                    options,
                                    uuid,
                                    is_opentext,
                                    remarks,
                                    question_status,
                                    is_demo,
                                    translation,
                                    is_condition_applied = false,
                                },
                                i
                            ) => (
                                <TableRow key={uuid}>
                                    {AuthStore.isAdminOrCoach && (
                                        <TableCellStyled>
                                            <Checkbox
                                                sx={checkBoxHeight}
                                                icon={<img src={CheckboxSvg} className="img" style={checkBoxStyle} />}
                                                checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                                checked={is_selected}
                                                value={uuid}
                                                onClick={e => {
                                                    handleOneClick(e, i);
                                                }}
                                            />
                                        </TableCellStyled>
                                    )}
                                    <TableCellStyled>
                                        <FlexBetween>
                                            <CommonLabel
                                                textSize={14}
                                                textWeight={400}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={20}
                                                label={translation['en']}
                                            />
                                            {AuthStore.isAdminOrCoach ? (
                                                <img
                                                    src={EditIcon}
                                                    alt="reason"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        dispatch(setCustomTab(true));
                                                        if (is_opentext) {
                                                            goToTab(5);
                                                        } else if (is_demo) {
                                                            goToTab(4);
                                                        } else if (is_custom) {
                                                            goToTab(3);
                                                        } else {
                                                            goToTab(2);
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </FlexBetween>
                                    </TableCellStyled>
                                    <TableCellStyled>
                                        <CommonLabel
                                            textSize={14}
                                            textWeight={400}
                                            textColor={colorsCode.TextSubtle}
                                            lineHeight={20}
                                            label={getSectionLabel({ is_custom, is_demo, is_opentext })}
                                        />
                                    </TableCellStyled>
                                    <TableCellStyled sx={{ width: `${pxToVw(220)}vw` }}>
                                        {options.length > 0 ? (
                                            <CommonLabel
                                                textSize={14}
                                                textWeight={400}
                                                textColor={colorsCode.TextSubtle}
                                                lineHeight={20}
                                                label={options[0].translation['en']}
                                            />
                                        ) : null}
                                        {options.length > 1 ? (
                                            <CommonLabel
                                                textSize={14}
                                                textWeight={400}
                                                textColor={colorsCode.TextSubtle}
                                                lineHeight={20}
                                                label={` ${options.length - 1} & more`}
                                                clickHandler={e => handleMenuClick(e, i)}
                                                td="underline"
                                                cursor="pointer"
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </TableCellStyled>
                                    <TableCellStyled
                                        sx={{
                                            width: `${pxToVw(176)}vw`,
                                        }}
                                    >
                                        {AuthStore.isAdminOrCoach ? (
                                            <Select
                                                disableUnderline
                                                id="survey-status"
                                                name="survey-status"
                                                variant="standard"
                                                IconComponent={props => (
                                                    <img src={ArrowDownIcon} {...props} alt="dropdown" />
                                                )}
                                                value={Object.values(QUES_STATUS_TYPE).filter(
                                                    item => item === question_status
                                                )}
                                                sx={{
                                                    width: `${pxToVw(176)}vw`,
                                                    boxShadow: 'none',
                                                }}
                                                onChange={e => handleStatus(e, uuid, is_condition_applied)}
                                            >
                                                {QUES_TYPE_COLL.map(({ value, slug }) => (
                                                    <MenuItem value={value} key={slug}>
                                                        <StatusBadge
                                                            bg={bg[slug]}
                                                            color={colorsCode[slug]}
                                                            label={value}
                                                            src={images(value)}
                                                            key={value}
                                                            cursor="pointer"
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ) : (
                                            showImageStatus(question_status)
                                        )}
                                    </TableCellStyled>
                                    <TableCellStyled sx={{ width: `${pxToVw(104)}vw` }}>
                                        {AuthStore.isAdminOrCoach && question_status === QUES_STATUS_TYPE.REJECTED ? (
                                            <FlexStartGap gap={pxToVw(4)}>
                                                <img
                                                    src={remarks ? EditIcon : plus}
                                                    alt="reason"
                                                    onClick={() => handleReason(uuid, remarks)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <CommonLabel
                                                    textSize={12}
                                                    textWeight={400}
                                                    textColor={colorsCode.primaryCyan}
                                                    lineHeight={16}
                                                    ls={pxToVw(0.6)}
                                                    clickHandler={() => handleReason(uuid, remarks)}
                                                    label={remarks ? 'EDIT REASON' : 'ADD REASON'}
                                                    customStyles={{ cursor: 'pointer' }}
                                                />
                                            </FlexStartGap>
                                        ) : (
                                            ''
                                        )}
                                        {AuthStore.isClientOrCst &&
                                            (question_status === QUES_STATUS_TYPE.REJECTED ? (
                                                <FlexStartGap gap={pxToVw(4)}>
                                                    <img
                                                        src={doc}
                                                        alt="reason"
                                                        onClick={() => handleReason(uuid, remarks)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </FlexStartGap>
                                            ) : (
                                                '-'
                                            ))}
                                    </TableCellStyled>
                                </TableRow>
                            )
                        )
                    ) : (
                        <TableRow>
                            <TableCellStyled colSpan={6} sx={{ textAlign: 'center' }}>
                                No Data
                            </TableCellStyled>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {anchorEl ? (
                <Menu
                    open={open}
                    onClose={() => setAnchorEl(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiMenu-paper': {
                            boxShadow: colorsCode.boxShadow,
                            width: `auto`,
                        },
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {optionsData?.map(({ translation }) => (
                        <MenuItem
                            key={translation['en']}
                            sx={{
                                cursor: 'default',
                                '&:hover': {
                                    background: 'none',
                                },
                                pointerEvents: 'none',
                            }}
                        >
                            {translation['en']}
                        </MenuItem>
                    ))}
                </Menu>
            ) : null}
        </Fragment>
    );
};

export default QuestionTable;
