import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';

import {
    FlexStartGap,
    FlexColumn,
    FlexInline,
    HeaderInLine,
    QuestionContent,
    QuestionList,
    TextWithCheckbox,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
} from '../../../../common/styled/styled';
import { Modulebody } from './OptionalModules';
import { CommonLabel, Pagetab } from '../../../../common/FormInputs/Common';
import { SETUP_SURVEY_TEXT } from '../../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { commonThemes } from '../../../../common/themes';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../assets/img/DisabledCheckbox.svg';

const OptionalQuestionContent = ({
    styles,
    optionalQuesData = [],
    dependentSubGroupInfo = [],
    handleQuestionResponse,
    handleOtionalModuleResponse,
    handleMultipleQuestionResponse,
    updateTabQuestions,
    updateSectionQuestion,
}) => {
    const dispatch = useDispatch();
    const stylesComp = commonThemes();
    const [tabId, setTab] = React.useState(null);

    React.useEffect(() => {
        if (tabId === null) {
            const { id } = optionalQuesData[0];
            setTab(id);
        }
        // eslint-disable-next-line
    }, [optionalQuesData, tabId]);

    const handleTab = newTabId => setTab(newTabId);

    const { sections: currentSections, use_sub_group: useSubGroup } = React.useMemo(() => {
        const indexToUse = optionalQuesData.findIndex(({ id }) => id === tabId);
        const { sections = [], use_sub_group = false } = optionalQuesData[indexToUse] || {};
        return {
            sections,
            use_sub_group,
        };
    }, [optionalQuesData, tabId]);

    const handleParentCheck = ({ checked }, secIndx, ques_sub_group_id, questions) => {
        dispatch(updateTabQuestions({ tabId, secIndx, checked }));
        if (ques_sub_group_id) {
            handleOtionalModuleResponse(ques_sub_group_id, checked);
        } else {
            const question_ids = questions.map(({ uuid }) => uuid);
            handleMultipleQuestionResponse(question_ids, checked);
        }
    };

    const handleColCheck = ({ checked }, secIndx, qIndex, questionId) => {
        dispatch(updateSectionQuestion({ tabId, secIndx, qIndex, checked }));
        handleQuestionResponse(questionId, checked);
    };

    return (
        <Paper
            elevation={2}
            sx={{ ...styles, alignSelf: 'stretch', marginTop: `${pxToVh(40)}vh` }}
            className="optionalQuestions"
        >
            <Modulebody>
                <CommonLabel
                    textWeight={500}
                    textSize={18}
                    lineHeight={28}
                    label={SETUP_SURVEY_TEXT.OPTIONAL_QUESTIONS_HEADING}
                    textColor={colorsCode.TextHeading}
                />
            </Modulebody>
            <Modulebody style={{ paddingTop: 0, paddingBottom: `${pxToVh(40)}vh` }} className="mainContent">
                <FlexStartGap gap={pxToVw(16)}>
                    <div style={{ alignSelf: 'stretch', marginBottom: `${pxToVh(18)}vh` }}>
                        <FlexInline>
                            {optionalQuesData.map(({ group_text, id }) => (
                                <Pagetab
                                    key={id}
                                    active={tabId === id}
                                    label={group_text}
                                    clickHandler={() => handleTab(id)}
                                />
                            ))}
                        </FlexInline>
                    </div>
                </FlexStartGap>

                <FlexColumn>
                    {currentSections.map(({ text, questions = [], is_included = false, uuid: secId }, secIndx) => {
                        const allQuesNotSelected = questions.some(({ use_in_survey }) => !use_in_survey);
                        const selectedQuesLen = questions.filter(({ use_in_survey }) => use_in_survey).length;

                        const makeQuestionDisable = questions.some(
                            ({ check_for_disable, dependent_sub_group_code }) => {
                                return (
                                    check_for_disable &&
                                    dependentSubGroupInfo.some(
                                        ({ sub_group_code, is_included }) =>
                                            !is_included && sub_group_code === dependent_sub_group_code
                                    )
                                );
                            }
                        );

                        return (
                            <AccordionStyled key={secIndx} defaultExpanded paddingTop={18} paddingBottom={18}>
                                <AccordionSummaryStyled
                                    expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                    pdTop={0}
                                    pdBottom={0}
                                >
                                    <HeaderInLine textSize={18} lineHeight={28} subTextSize={14}>
                                        {useSubGroup ? (
                                            <TextWithCheckbox>
                                                <Box onClick={e => e.stopPropagation()} className="innerChild">
                                                    <Checkbox
                                                        disableRipple
                                                        checked={is_included}
                                                        onChange={({ target }) =>
                                                            handleParentCheck(target, secIndx, secId)
                                                        }
                                                        sx={{
                                                            padding: 0,
                                                            borderRadius: 0,
                                                        }}
                                                        icon={<img src={CheckboxSvg} alt="" className="img" />}
                                                        checkedIcon={<CheckBoxIcon />}
                                                    />
                                                </Box>
                                                {text}
                                            </TextWithCheckbox>
                                        ) : (
                                            <span className="questionCategory">{text}</span>
                                        )}
                                        {!useSubGroup && (
                                            <React.Fragment>
                                                <span className="midLine" />
                                                <span className="selectedQues">
                                                    ({selectedQuesLen}/{questions.length}) selected
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </HeaderInLine>
                                </AccordionSummaryStyled>
                                <AccordionDetailsStyled
                                    style={{
                                        paddingTop: 0,
                                        borderBottom:
                                            useSubGroup && currentSections.length > 1
                                                ? `1px solid ${colorsCode.Neutral10}`
                                                : 0,
                                    }}
                                >
                                    {useSubGroup ? (
                                        <QuestionList>
                                            {questions.map(({ question_text, uuid }) => (
                                                <FlexStartGap key={uuid} className="grid-item">
                                                    <CommonLabel
                                                        textColor={colorsCode.Neutral80}
                                                        textSize={14}
                                                        lineHeight={20}
                                                        textWeight={400}
                                                        label={question_text}
                                                        component="li"
                                                    />
                                                </FlexStartGap>
                                            ))}
                                        </QuestionList>
                                    ) : (
                                        <QuestionContent textSize={14} lineHeight={20}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    disabled={makeQuestionDisable}
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Checkbox
                                                            disableRipple
                                                            checked={!allQuesNotSelected}
                                                            indeterminate={!!(allQuesNotSelected && selectedQuesLen)}
                                                            onChange={({ target }) =>
                                                                handleParentCheck(target, secIndx, secId, questions)
                                                            }
                                                            sx={{
                                                                height: 0,
                                                                width: 0,
                                                                padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                                borderRadius: 0,
                                                            }}
                                                            icon={
                                                                <img
                                                                    src={
                                                                        makeQuestionDisable
                                                                            ? DisabledCheckboxSvg
                                                                            : CheckboxSvg
                                                                    }
                                                                    alt=""
                                                                    className="img"
                                                                />
                                                            }
                                                            checkedIcon={<CheckBoxIcon />}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={`${stylesComp.formControlLabel} ${
                                                                makeQuestionDisable ? 'isDisabled' : ''
                                                            }`}
                                                            style={{ fontWeight: 500 }}
                                                        >
                                                            {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                                        </Typography>
                                                    }
                                                />
                                                {questions.map(({ question_text, use_in_survey, uuid }, qIndex) => {
                                                    return (
                                                        <FormControlLabel
                                                            disabled={makeQuestionDisable}
                                                            sx={{ margin: 0 }}
                                                            key={`${secIndx}-${qIndex}-${uuid}`}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    checked={use_in_survey}
                                                                    onChange={({ target }) =>
                                                                        handleColCheck(target, secIndx, qIndex, uuid)
                                                                    }
                                                                    icon={
                                                                        <img
                                                                            src={
                                                                                makeQuestionDisable
                                                                                    ? DisabledCheckboxSvg
                                                                                    : CheckboxSvg
                                                                            }
                                                                            alt=""
                                                                            className="img"
                                                                        />
                                                                    }
                                                                    checkedIcon={<CheckBoxIcon />}
                                                                    sx={{
                                                                        height: 0,
                                                                        width: 0,
                                                                        padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                                        borderRadius: 0,
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <Typography
                                                                    className={`${stylesComp.formControlLabel} ${
                                                                        makeQuestionDisable ? 'isDisabled' : ''
                                                                    }`}
                                                                    dangerouslySetInnerHTML={{ __html: question_text }}
                                                                />
                                                            }
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                        </QuestionContent>
                                    )}
                                </AccordionDetailsStyled>
                            </AccordionStyled>
                        );
                    })}
                </FlexColumn>
            </Modulebody>
        </Paper>
    );
};

export default OptionalQuestionContent;
