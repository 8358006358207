import React, { useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import { CommonLabel, Img } from '../../../../../../common/FormInputs/Common';
import SearchBar from '../../../../../../common/FormInputs/SearchBar';
import {
    FlexColumn,
    FlexBetween,
    Flex,
    FlexRow,
    FlexCenterWithAlign,
    FlexColumnCenter,
    CTA,
} from '../../../../../../common/styled/styled';
import remove from '../../../../../../assets/img/remove.svg';
import CloseIcon from '../../../../../../assets/img/close.svg';
import RedWarningIcon from '../../../../../../assets/img/red_warning.svg';
import tick from '../../../../../../assets/img/check.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SaveStatusQuestions } from '../../../../../../store/actions/Surveys/FinalizeActions';
import { QUES_STATUS_TYPE } from '../../../../../../global-constants';
import { Dialog, DialogTitle, Icon } from '@mui/material';
import { DialogActionsStyled } from '..';

const Actions = ({ AuthStore, state, si, search, searchText }) => {
    const [showDeletePopUp, setShowDeletePopUp] = useState({ show: false, flag: '', qstatus: '' });
    const dispatch = useDispatch();
    const selectedLength = state.length ? state.filter(({ is_selected }) => is_selected === true).length : 0;
    const handleApprovedRejected = (flag, qstatus) => {
        if (state?.filter(ele => ele.is_condition_applied)?.length && flag) {
            setShowDeletePopUp({ show: true, flag: flag, qstatus: qstatus });
        } else {
            const data = state
                .filter(({ is_selected }) => is_selected)
                .map(({ uuid }) => {
                    return { question_id: uuid, remarks: '', is_rejected: flag, question_status: qstatus };
                });
            dispatch(SaveStatusQuestions({ surveyId: si?.uuid, requestParams: data }));
        }
    };

    return (
        <FlexColumn
            className="column"
            style={{
                border: 'none',
            }}
        >
            <FlexBetween className="stretch" gap={pxToVw(20)}>
                <CommonLabel
                    textSize={18}
                    textWeight={400}
                    textColor={colorsCode.TextSubtle}
                    lineHeight={28}
                    label="Custom questions"
                />
                <FlexDiv gap={20}>
                    {AuthStore.isAdminOrCoach && selectedLength ? (
                        <FlexDiv gap={8}>
                            <img src={tick} alt="" style={{ cursor: 'pointer' }} />
                            <CommonLabel
                                textSize={12}
                                textWeight={400}
                                textColor={colorsCode.PrimaryDeepBlue}
                                lineHeight={16}
                                ls={pxToVw(0.6)}
                                clickHandler={() => handleApprovedRejected(false, QUES_STATUS_TYPE.APPROVED)}
                                label="APPROVE SELECTED"
                                customStyles={{ cursor: 'pointer' }}
                            />
                        </FlexDiv>
                    ) : null}
                    {AuthStore.isAdminOrCoach && selectedLength ? (
                        <FlexDiv gap={8}>
                            <img src={remove} alt="" style={{ cursor: 'pointer' }} />
                            <CommonLabel
                                textSize={12}
                                textWeight={400}
                                textColor={colorsCode.PrimaryDeepBlue}
                                lineHeight={16}
                                ls={pxToVw(0.6)}
                                clickHandler={() => handleApprovedRejected(true, QUES_STATUS_TYPE.REJECTED)}
                                label="REJECT SELECTED"
                                customStyles={{ cursor: 'pointer' }}
                            />
                        </FlexDiv>
                    ) : null}
                    <SearchBar placeholder="Search" onChangeFn={search} val={searchText} callApi={true} />
                </FlexDiv>
            </FlexBetween>
            {showDeletePopUp.show ? (
                <Dialog
                    open={showDeletePopUp.show}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(520)}vw`,
                            width: `${pxToVw(520)}vw`,
                            borderRadius: '15px',
                        },
                    }}
                >
                    <FlexRow justifyContent="flex-end" margin="1vw 1vw 0 0">
                        <Icon
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: `${pxToVw(16)}vw`,
                                top: `${pxToVh(16)}vh`,
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt=""
                                style={{
                                    height: `${pxToVh(20)}vh`,
                                    width: ` ${pxToVw(20)}vw`,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowDeletePopUp({ show: false, flag: '', qstatus: '' })}
                            />
                        </Icon>
                    </FlexRow>
                    <DialogTitle>
                        <FlexCenterWithAlign>
                            <Img image={RedWarningIcon} h={pxToVh(40)} w={pxToVw(40)} alt="" />
                        </FlexCenterWithAlign>
                        <FlexCenterWithAlign>
                            <CommonLabel
                                textSize={20}
                                textColor={colorsCode.TextHeading}
                                textWeight={500}
                                lineHeight={36}
                                label="Reject question"
                            />
                        </FlexCenterWithAlign>
                        <FlexColumnCenter gap="2" margin="20px 0">
                            <FlexCenterWithAlign>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={300}
                                    textAlign="center"
                                    label="This question has display logic applied to it. Rejecting it will also reject the applied logic."
                                />
                            </FlexCenterWithAlign>
                            <FlexCenterWithAlign>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={300}
                                    textAlign="center"
                                    label="Are you sure you want to continue?"
                                />
                            </FlexCenterWithAlign>
                        </FlexColumnCenter>
                    </DialogTitle>

                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => setShowDeletePopUp({ show: false, flag: '', qstatus: '' })}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            onClick={() => {
                                const data = state
                                    .filter(({ is_selected }) => is_selected)
                                    .map(({ uuid }) => {
                                        return {
                                            question_id: uuid,
                                            remarks: '',
                                            is_rejected: showDeletePopUp.flag,
                                            question_status: showDeletePopUp.qstatus,
                                        };
                                    });
                                dispatch(SaveStatusQuestions({ surveyId: si?.uuid, requestParams: data }));
                                setShowDeletePopUp({ show: false, flag: '', qstatus: '' });
                            }}
                        >
                            Reject
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}
        </FlexColumn>
    );
};

const FlexDiv = styled(Flex)`
    gap: ${({ gap }) => pxToVw(gap)}vw;
    alignself: stretch;
    align-items: center;
    img {
        height: ${pxToVh(16)}vh;
        width: ${pxToVw(16)}vw;
    }

    .MuiInputBase-input {
        font-size: ${pxToVh(14)}vh;
        line-height: ${pxToVh(20)}vh;
        color: ${colorsCode.Neutral80};

        &::placeholder {
            color: ${colorsCode.TextSubtle};
            opacity: 1;
        }
    }
`;

export default Actions;
