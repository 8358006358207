import React from 'react';
import { CTA, FlexBetween, FlexColumn, FlexStartGap } from '../../../../../common/styled/styled';
import { Box } from '@mui/material';
import { CommonLabel } from '../../../../../common/FormInputs/Common';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import Text from '../../../../KnowledgeCenter/Text';

const LangSidebar = ({ title, languages = [], currentLang, errorState, onLanguageSelect }) => {
    const getBackgroundColor = short_code => {
        if (errorState && currentLang === short_code) {
            return colorsCode.error;
        } else if (currentLang === short_code) {
            return colorsCode.Neutral05;
        } else {
            return colorsCode.white;
        }
    };

    const getTextColor = (short_code, isBadge) => {
        if (errorState && currentLang === short_code) {
            return colorsCode.white;
        } else if (currentLang === short_code) {
            return isBadge ? colorsCode.Neutral30 : colorsCode.PrimaryDeepBlue;
        } else {
            return isBadge ? colorsCode.Neutral30 : colorsCode.TextSubtle;
        }
    };

    return (
        <LangSideBarDiv className="langBar">
            <Box className="title">
                <CommonLabel
                    textSize={14}
                    lineHeight={20}
                    textWeight={300}
                    textColor={colorsCode.Neutral80}
                    label={title}
                />
            </Box>
            {languages.length
                ? languages.map(({ name, short_code, default: isDefault = false }) => (
                      <FlexBetween
                          className="languageClass"
                          key={short_code}
                          onClick={() => onLanguageSelect(short_code)}
                          bg={getBackgroundColor(short_code)}
                      >
                          <FlexStartGap gap="1" style={{ alignItems: 'center' }}>
                              <LangLogoDiv isDefault={currentLang === short_code}>
                                  <Text
                                      fontColor={colorsCode.white}
                                      fontSize={short_code?.length > 2 ? '13px' : '16px'}
                                      lineHeight={12}
                                      label={
                                          short_code === 'en_gb' || short_code === 'es419'
                                              ? short_code?.slice(0, 3).concat(' ', short_code?.slice(3))
                                              : short_code
                                      }
                                      fontWeight={500}
                                      textAlign={'center'}
                                      sx={{ minWidth: '35px' }}
                                  />
                              </LangLogoDiv>
                              <CommonLabel
                                  textSize={14}
                                  lineHeight={20}
                                  textWeight={300}
                                  textColor={getTextColor(short_code)}
                                  label={name}
                                  cursor="pointer"
                              />
                          </FlexStartGap>
                          {isDefault ? (
                              <CTA
                                  pl={8}
                                  pt={2}
                                  bg="transparent"
                                  style={{ borderRadius: '15px' }}
                                  color={getTextColor(short_code, true)}
                                  size={12}
                                  fw={500}
                                  lh={16}
                                  bcolor={getTextColor(short_code, true)}
                              >
                                  Default
                              </CTA>
                          ) : null}
                      </FlexBetween>
                  ))
                : null}
        </LangSideBarDiv>
    );
};

export const LangSideBarDiv = styled(FlexColumn)`
    &.langBar {
        align-items: flex-start !important;
        align-self: stretch;
        width: ${pxToVw(225)}vw !important;
        border-right: 1px solid ${colorsCode.Neutral10};
        cursor: pointer;
    }
    .title {
        align-items: center !important;
        padding: ${pxToVh(21)}vh ${pxToVw(16)}vw;
        width: ${pxToVw(225)}vw !important;
        border-bottom: 1px solid ${colorsCode.Neutral10};
    }
    .languageClass {
        align-self: stretch;
        padding: ${pxToVh(21)}vh ${pxToVw(16)}vw;
        border-bottom: 1px solid ${colorsCode.Neutral10};
    }
`;

const LangLogoDiv = styled.div`
    border-radius: 50%;
    background: ${colorsCode?.logoBgColorActive};
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    opacity: ${({ isDefault }) => (!isDefault ? 0.5 : 1)};
`;

export default LangSidebar;
