/* eslint-disable */
/* istanbul ignore file */
const ENV = process.env.REACT_APP_ENVIRONMENT;
const AWS_REGION = process.env.REACT_APP_AWS_REGION;
let REACT_APP_OHI_ANALYTICS_PORTAL_PATH = 'https://stg3ohi-dev.orglabsolutions.com/';
var REACT_APP_OIDC_CONFIG_URL = 'https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration';
var REACT_APP_CLIENT_ID = '1ff7cf44-5cba-49d1-9b5f-a34c9a9bf25f';
var REACT_APP_BASE_PATH_URL = 'https://dev.ohisurvey.com';
var REACT_APP_REDIRECT_BASE_URL = 'http://localhost:3000';
//var REACT_APP_UI_BASE_PATH = ['dev', 'stg'].includes(ENV) ? '/self-serve' : '/';
var REACT_APP_UI_BASE_PATH = '/self-serve';

if (ENV === 'dev') {
    REACT_APP_REDIRECT_BASE_URL = 'https://dev.ohisurvey.com/self-serve';
    REACT_APP_BASE_PATH_URL = 'https://dev.ohisurvey.com';
}

if (ENV === 'stg') {
    REACT_APP_REDIRECT_BASE_URL = 'https://stg.ohisurvey.com/self-serve';
    REACT_APP_BASE_PATH_URL = 'https://stg.ohisurvey.com';
    REACT_APP_OHI_ANALYTICS_PORTAL_PATH = 'https://stg-analytics.orglabsolutions.com/';
}

if (ENV === 'prod') {
    REACT_APP_OIDC_CONFIG_URL = 'https://auth.mckinsey.id/auth/realms/r/.well-known/openid-configuration';
    if (AWS_REGION === 'us-east-1') {
        REACT_APP_CLIENT_ID = 'cde2fab4-8fc0-4ff7-b6b8-0ad59e7c9c86';
        REACT_APP_REDIRECT_BASE_URL = 'https://us.ohisurvey.com/self-serve';
        REACT_APP_BASE_PATH_URL = 'https://us.ohisurvey.com';
        REACT_APP_OHI_ANALYTICS_PORTAL_PATH = 'https://us-ohi-analytics.orglabsolutions.com';
    } else {
        REACT_APP_CLIENT_ID = '83c25335-21df-4dc5-9d3c-edf598825891';
        REACT_APP_REDIRECT_BASE_URL = 'https://ohisurvey.com/self-serve';
        REACT_APP_BASE_PATH_URL = 'https://ohisurvey.com';
        REACT_APP_OHI_ANALYTICS_PORTAL_PATH = 'https://ohi-analytics.orglabsolutions.com';
    }
}

export const CONFIG = {
    BASE_URL: REACT_APP_REDIRECT_BASE_URL,
    OIDC_URL: REACT_APP_OIDC_CONFIG_URL,
    CLIENT_ID: REACT_APP_CLIENT_ID,
    BASE_PATH_APP: REACT_APP_BASE_PATH_URL,
    BASE_UI_PATH: REACT_APP_UI_BASE_PATH,
    ANALYTICS_PORTAL_LINK: REACT_APP_OHI_ANALYTICS_PORTAL_PATH,
};

export const LABELS = Object.freeze({
    DOWNLOAD: 'Download',
    ONLY_ENGLISH_AVAILABLE: 'This module is only available in English language',
    WELCOME: 'Welcome to the culture <br /> and experience platform',
    EXTERNAL_INFO:
        'If you are not a McKinsey colleague, you will need to log in with Okta Verify multi-factor authentication',
    OKTA_TEXT: 'for instructions on how to set up your Okta Verify account',
    LOGIN_TEXT: 'Login',
    CREATE_SURVEY_BTN: 'Create Survey',
    CREATE_SURVEY_LBL: 'Create Survey',
    NO_SURVEY_ADDED: 'No survey added',
    CREATE_SURVEY_LABEL: 'Create a unique survey for your organization',
    CREATE_SURVEY_INFO:
        'Browse through a list of templates to select the one that is right for your organisation. Watch a demo to learn how to create surveys and the unique features that the platform provides',
    CREATE_SURVEY: {
        Overview_Title: {
            title: 'Survey templates',
            details: 'Company details',
            details_npm: 'Organization details',
            salesforce_info: 'McKinsey Salesforce information',
            Name: 'Client name',
            Link: 'Survey link',
            Type: 'Survey type',
            Wording_Title: 'Survey wordings',
            TYPE: 'Select survey link type',
            SURVEY_TYPE: 'Select any one type',
        },
        Salesforce_Info: {
            opportunity_name: 'Opportunity name',
            opportunity_name_log: 'Opportunity name log',
            opportunity_info: 'Adding this information is optional in case of test surveys',
        },
        CLIENT_NAME: 'Client name',
        People_responsible: 'People responsible',
        owners: 'Survey owners',
        managers: 'Project manager',
        sdm_analyst: 'SDM/Analyst',
        ORG: 'Organization name',
        Industry: 'Industry',
        industry: 'Industry',
        geography: 'Geography',
        type_of_team: 'Type of team',
        team_size: 'Team size',
        ed_name: 'ED/DCS name',
        Emp_count: 'How many employees do you have in your organization',
        exclude_eng: 'Do not show English (US) to survey participants',
        select_base: 'Select survey base language',
        select_trans: 'Select additional languages',
        select_context: 'Which of the following best describes the team’s current context?',
        TYPE: 'Select survey link type',
        INDIVIDUAL: 'Individual',
        INDIVIDUAL_PLUS_GENERIC: 'Individual + Generic',
        INDIVIDUAL_PLUS_GENERIC_DESC:
            'Allows you to generate both unique links/passcodes and a common link for different sets of respondents.',
        INDIVIDUAL_DESC: 'Allows you to generate unique links for each respondent.',
        GENERIC_PASSOCDE_SUBTEXT: 'You may use the pre-filled passcode or create your own',
        GENERIC: 'Generic',
        FULL_OHI: 'Full OHI',
        FULL_OHI_DESC: 'Complete survey featuring the entire set of template questions',
        PULSE: 'Pulse',
        PULSE_DESC: 'Condensed version with the option to decrease questions',
        GENERIC_DESC: 'Allows you to generate common link and common passcode for all respondents.',
        SURVEY_LIMIT: 'Maximum Survey Limit',
        DEMO_LABEL: '',
        OPEN_END_LABEL: '',
        DEMO_LIMIT_LABEL: '',
        OPEN_END_LABEL_LIMIT: 'Limit of custom questions',
    },
    DELETE_CLIENT_TITLE: 'Delete Client',
    DELETE_CLIENT_DESC: 'Do you really want to delete this client?',
    DELETE_BULK_CLIENT_DESC: 'Do you really want to delete these clients?',
    DELETE_BTN: 'Yes, Delete',
    DELETE_CTA: 'Delete',
    CONFIRM_DELETE: 'Yes, Delete',
    CONFIRM_DEL_USER_MSG_DESC: 'This will delete the user along with the profile details.',
    CONFIRM_DEL_MSG: 'Are you sure you want to proceed?',
    CONFIRM_DEL_MSG_SURVEY: 'Are you sure you want to delete survey?',
    DELETE_OPPORTUNITY_HEADING: 'Delete opportunity name',
    CONFIRM_DEL_MSG1_OPPORTUNITY: 'Deleting this opportunity name will delete any surveys linked to it.',
    CONFIRM_DEL_MSG2_OPPORTUNITY: 'Are you sure you want to continue?',
    DELETE_TITLE: 'Delete user',
    CANCEL_BTN: 'Cancel',
    DELETE_CLIENT_SUCCESS: 'Client deleted successfully',
    CHOOSE_TEMPLATE: 'Surveys',
    HOME: 'Home',
    SURVEY_BUILDER: 'Survey builder',
    CLIENT_MGMT: 'Client management',
    CONFIRMATION: 'Confirmation',
    SUBMIT: 'Submit',
    UPDATE: 'Update',
    ADD: 'Add',
    EXPORT: 'Export',
    FILTER: 'Filters',
    WATCH_DEMO_DESC: 'Before you begin your journey on Self Serve, we suggest you take a demo of our platform.',
    DASHBOARD: {
        TASK: 'Your tasks',
        Continue: 'Continue setup',
        Manage: 'Manage surveys',
        Closed: 'Closed surveys',
        Live: 'Live surveys',
        Overview: 'Overview',
        Create: 'Create survey',
        CloseSurvey: 'Close survey',
    },
    DELETE_LANGUAGE: {
        HEADING: 'Delete language',
        DESC: 'Deleting a language will result in deletion of all the data that has been added for the same.',
        CONFIRM_TEXT: 'Are you sure you want to continue?',
    },
    ADD_EDIT_USER: {
        NAME: 'Name of the user',
        EMAIL: 'Email ID of the user',
        ROLE: 'Role',
        CLIENT: 'Client name',
        CREATE_CLIENT: 'Create client',
        VALID: 'User valid till',
    },
    FILTER_MODAL: {
        MAIN_TITLE: 'Filters',
        CLEAR_TITLE: 'Clear filters',
        SURVEY_CREATED: 'Surveys created',
        SURVEY_TYPE: 'Survey type',
        ROLE_TYPE: 'Role type',
        ACTIVE: 'Status type',
        CANCEL_BTN: 'Cancel',
        APPLY_BTN: 'Apply',
        SURVEY_STATUS: 'Status',
        LINK_TYPE: 'Link type',
        LANGUAGE: 'Survey language',
        SHOW_TEST_SURVEY: 'Show test survey',
        MAINTENANCE_MODE: 'Maintenance mode',
    },
    USER: {
        MANAGE: 'Manage users & clients',
        ADD_USER: 'Add user',
        ADD_CLIENT: 'Add client',
        UPDATE_USER: 'Update user',
    },
    CLIENTS_HEAD: ['Client name', 'Link type', 'Survey type', 'User', 'Survey created'],
    USERS_HEAD: ['User name', 'Role', 'Client name', 'Email ID', 'Survey assigned', 'Last login'],
    SURVEYS: {
        NO_SURVEY: 'No Survey Found',
    },
    SURVEY_MODULE: {
        BASIC_INFO_TEXT:
            'Below is some basic information about your survey for you to update. Please note that you will not be able \
    to make further changes after creating your survey',
        SURVEY_LANG: 'Survey Languages',
        labels: {
            surveyTitle: 'Survey title',
            surveyLabel: 'Name your survey',
        },
        WORDING: {
            TITLE: 'Replace the elements below with content specific to your organization. If your survey has more than one language, please be sure to check that the information is correct for each survey language.',
            TITLENPM:
                'Review and confirm the elements below with content specific to your organization. If your survey has more than one language, please be sure to check that the information is correct for each survey language.',
        },
        info_title: {
            nameYourSurvey:
                "Please enter the client name. This is technical information that won't appear anywhere in the survey",
            nameYourSurveyNpm:
                "Please enter the organization name. This is technical information that won't appear anywhere in the survey",
            emp_count: 'This number will be used for calculating survey response rate',
            emp_count2: 'Please enter the number of employees expected to participate in the survey.',
            eng_us: 'English (US) is set as default language for all surveys but can be set as not visible for survey participants',
            context: 'Explanation',
            toggle: 'Select if you want to test a platform - it will NOT be possible to launch the survey for a client',
            toggle_npm: 'Select if you want to test a platform - it will NOT be possible to launch the survey',
            resurvey:
                'It is considered a resurvey only if the same group is being surveyed as the former year survey. E.g. If the whole company was surveyed previously, it would only be a resurvey if the whole company was resurveyed, even if a sampling approach was used. If only part of the company was surveyed previously, it would only be a resurvey if the same part of the company was resurveyed [e.g., a certain BU, division, region, etc.]',
            industry: "Select an industry which describes the best client's area",
            geography: 'Select the location of your client’s company',
            type_of_team: 'E.g. Board, BU/ division leadership, Executive team',
            team_size: 'Please enter number of team members',
            ed_name: 'Please enter ED/ DSC name. You can add also lead Expert name',
            owners_tooltip: 'Owner of the survey, responsible for the setup in self-serve portal',
            analyst_tooltip: 'SDM or Analyst from OST responsible for the survey',
            managers_tooltip: 'Project Manager from Helpdesk (if any)',
            overview_Title:
                'Please refrain from editing the codes (^client_name^) as they ensure uniform representation of the name across all areas.',
        },
        OPTIONAL_MODULES: {
            TITLE: 'Optional modules',
            ADD_ALL: 'Add All',
            CLEAR: 'Clear',
            PREVIEW_OPT_MOD_QUE: 'Preview',
            ADD_MODULE: 'Add module',
            REMOVE_MODULE: 'Remove module',
            ADD_OUTCOME: 'Add outcome',
            REMOVE_OUTCOME: 'Remove outcome',
            ADD_PRACTICE: 'Add practice',
            REMOVE_PRACTICE: 'Remove practice',
            ADDITIONAL_TTILE: 'Question modules',
            valuesModule:
                "Measure employees' perception of your organization's current set of values compared with the organization's desired set of values",
            ADDITIONAL_DESC:
                'In this section, you have the option to include additional question modules in your survey.',
        },
        ADDITIONAL_QUES: {
            TITLE: 'Additional survey questions',
            DESC: 'In this section, you can add scale-based questions specific to your organization',
            FREQ_TITLE: 'Question response preview',
            AGREE_DESC: 'Agreement scale questions measure the level of agreement and disagreement to statements',
            FREQ_DESC: 'Frequency scale questions measure the prevalence of specific actions',
            NO_QUES_ADDED: 'You have not added any questions. Click on  ‘Add Question’ to get started',
            BTN_ADD_QUES: 'Add question',
            FREQ_INFO: 'Custom added questions require approval at later stage of this setup process.',
            QUESTION_TITLE: 'Questions',
            PREVIEW_TEXT_LABEL: 'The scale below is for preview purposes only. No actions can be taken.',
        },
        DISPLAY_LOGIC: {
            TITLE: 'Display logic',
            DESC: 'In this section, you can set a logic for your questions and responses to be displayed',
        },
        OPEN_END_QUES: {
            TITLE: 'Open-text questions',
            DESC: 'In this section, you can add or create custom open-text questions for your survey',
            QUESTION_PREVIEW_TITLE: 'Question response preview',
            STANDARD_PREVIEW_DESC:
                'The following questions are an optional opportunity for you to provide additional detail. Please be aware that comments will be shared with your institution exactly as written and any information you include will not be removed. However, your answers will be grouped to help prevent individual answers from being associated with you. To help prevent identification, please do not provide any personal information (names, context, unique experiences, etc.) that could be used to identify you or other individuals.',
            CUSTOM_PREVIEW_DESC:
                'Custom questions allow you to tailor the questions to your organization’s specific needs and areas of focus',
            PREVIEW_TEXTBOX_PLACEHOLDER: 'Enter your response',
            STANDARD_QUESTION_DESC:
                'These open-text questions are pre-defined and optional, you can include them into your survey',
            QUESTION_TITLE: 'Questions',
            INCLUDED_BY_DEFAULT: 'Included by default',
            INCLUDE_IN_SURVEY: 'Include in survey',
            CUSTOM_CHIP_TEXT: 'Custom added questions require approval at later stage of this setup process.',
            ADD_QUESTION_CTA: 'Add question',
            NO_QUES_ADDED: 'You have not added any questions. Click on  ‘Add Question’ to get started',
            PREVIEW_TEXT_LABEL: 'This text field is solely for preview purposes. No actions can be taken.',
        },
        Warning_create_survey: {
            para1: 'You already have a survey setup in progress. Do you still want to create a new survey?',
            para2: 'Please note that this will impact the number of survey you are allowed to create',
            para3: 'Are you sure you want to proceed?',
            btnAgree: 'Create new survey',
            btnCancel: 'Resume draft survey',
        },
    },
    LANG_INFO: 'Please visit each language option to review/customize the custom demographic questions',
    STANDARD_DEMO: {
        CUSTOM_TITLE:
            'Here you can add a custom demographic question to gather additional insights on your staff’s location, e.g., specific regions, cities, districts within a city. This question will be included in your survey only after review by an Admin. The options “Other” and “Prefer not to answer” must be included in your custom demographic question. Please note that data can only be shown for demographic groups with 10 or more respondents.',
        CUSTOM_TITLE_NPM:
            'Here you can add a custom demographic question to gather additional insights on your staff’s location, e.g., specific regions, cities, districts within a city. This question will be included in your survey only after review by an OHI Portal Admin. The options “Other” and “Prefer not to answer” must be included in your custom demographic question. Please note that data can only be shown for demographic groups with 10 or more respondents.',
        CUSTOM_TITLE_2:
            'Collection of demographic information may be subject to privacy, employment, and other laws and cultural context that varies significantly across geographies. The OHI survey is currently accepting only certain demographics. Please note that the following sensitive demographic categories will automatically be rejected at this time: Race, Ethnicity, Gender, Sexual Identity, Marital Status.',
        STANDARD_TITLE:
            'Standard demographic questions allow you to better understand certain background characteristics of an audience, including job lever, function, location or tenure. These predefined questions are optional and you may not include them in your survey. Standard demographics have been translated and your choice in the default language applies to other languages. Note: the option to include or exclude question is only available in default language.',
        STANDARD_TITLE_NPM:
            'Standard demographic questions allow you to better understand certain background characteristics of an audience, including job level, function, location, and tenure. These predefined standard demographic questions are mandatory for all respondents, with one optional standard demographic question (i.e., age) that you may include in your survey, as needed. Standard demographic questions have been translated to all available languages. Note: If you choose to provide the optional standard age demographic question to your respondents, please select this option on the default language page.',
        STANDARD_TITLE_2:
            'Select if you want to use one of standard demographics with all available categories. If you want to customize categories\' list or add other questions, use "Custom demographic question" option.',
        STANDARD_TITLE_2_NPM:
            'Select if you want to add optional standard age question. If you want to include customized question in your survey, move to "Custom demographic questions" tab.',
        TEXT_EDIT_STANDARD_DEMO:
            'Standard demographic questions allow you to better understand certain background characteristics of an audience, including age, race, ethnicity, income, work situation, or marital status.',
        TAB_ONE: 'Standard demographic questions',
        TEXT_EDIT_HEADING:
            'In this section, you can edit and update the text for the demographic questions in your survey.',
        HEADING:
            'In this section, you can review or create demographic questions for your survey. Demographic questions created here will appear in the survey for the respondent to select an answer. If you wish to upload demographic data in advance of the survey do not create the demographic questions here, upload the data on the Respondent upload tab.',
        HEADING_NPM:
            'In this section, you can review or create demographic questions for your survey. Demographic questions created here will appear in the survey for the respondent to select an answer.',
    },
    FINALIZE: {
        message1:
            "Please note once you finalize, you wouldn't be able to modify the survey setup and rejected questions will not be a part of survey.",
        message2: 'Are you sure you want to proceed?',
    },
    SURVEY_LINK: {
        message1: 'Thank you for setting up your organization’s OHI Survey',
        message1_npm: 'Thank you for setting up your organization’s OHI for Nonprofits survey',
        message2:
            'Your organization is now ready to start taking the survey. Your colleagues can access the survey using the link or QR code below. This is not a unique link, so it can be easily shared by email and other formats with your collegues.',
        link: 'To create formal emailers to share the above link',
        btn: 'Click here',
        ditribute:
            'Your organization’s survey has not been finalized yet. Please finalize your survey by navigating back to the "Set up survey” tab. Your survey link and QR code will be available on this page after you finalize your survey.',
    },

    WORKPLACE_REMOVE_SUBTEXT: 'Please provide a reason for skipping "Workplace flexibility" section questions',
    ORG_NAME_ERROR: "Enter your organization's name",
    MAX_SURVEY: 'Maximum survey already created for this client.',
    FOUR_CHARS_ERROR: 'Search for :name: (start typing first 3 letters)',
    INFO_OTHER_LANG:
        "Add questions in the default/base language to view them in other languages. Please note that the option to add questions is only available in the default language and not other languages. Click 'Add question' in the default language to get started.",
    GLOSSARY_OTHER_LANG:
        "Add glossary in the default/base language to view them in other languages. Please note that the option to add glossaries is only available in the default language and not other languages. Click 'Add glossary' in the default language to get started.",
});

export const MESSAGE = Object.freeze({
    Success_Title: 'Success',
    Warning: 'Warning',
    Error_Title: 'Exception',
    Success_Add_Client: 'Client has been added successfully!',
    Error_Add_Client: 'Error occurred while processing your request.',
    Success_Edit_Client: 'Client details updated successfully!',
    Error_Occurred: 'Error Occurred!',
    Success_Delete_User: 'User deleted successfully!',
    Success_Add_User: 'User added successfully!',
    Success_Edit_User: 'User details updated successfully!',
    Required_message: 'This field is required.',
    ALL_fields_req: 'Please fill all the fields.',
    Success_survey: 'Survey created successfully.',
    Question_error: 'Error while saving standard demo questions',
    Question_saved: 'Question saved successfully',
    Question_del_error: 'Error while deleting questions',
    Question_del_saved: 'Question deleted successfully',
    Question_error_general: 'Error while saving question',
    QUESTION_STATUS: 'Question status changed successfully.',
    QUESTION_UNDER_REVIEW: 'Questions has been sent to admin for review.',
    QUESTION_REVIEWED: 'Questions reviewed successfully and notified user.',
    QUESTION_REORDERED: 'Questions reordered successfully.',
    QUESTION_REASON_CHANGE: 'Reason for rejection added successfully',
    logo_del_error: 'Error while deleting logo',
    logo_del_success: 'Logo deleted successfully',
    success_delete_survey: 'Survey deleted successfully!',
    error_delete_survey: 'Error while deleting survey',
    Detail_change_Logout_note: 'You have updated your details, please relogin.',
    unsaved: 'Please save your unsaved questions before proceeding.',
    pulseError: 'Select a minimum of 1 outcome or 1 practice.',
    finalizePulseError: 'Select a minimum of 1 outcome or 1 practice under “question modules” section.',
    SYSTEM_MESSAGES_UNSAVED: 'Please save your unsaved system messages before proceeding.',
    SALESFORCE_FINALIZE: 'Ensure salesforce legal checks are completed successfully.',
    HTML_TAG_ERROR: 'HTML tags are not allowed in the input',
    UNSAVED_DATA: 'Please save your unsaved data before proceeding.',
});

export const ERROR_MSG = Object.freeze({
    MAX_SURVEY_LIMIT: 'Please enter valid number',
    VALID_EMAIL: 'Invalid email format',
    CLIENT_ERROR: 'Error occured while processing your request. Please try again after sometime.',
    USER_EXISTS: ' User with this email already exists.',
});

export const REGEX = Object.freeze({
    isnumber: /^\d+$/,
    name: /^([a-zA-Z\'\+ ?]*)$/,
    // clientName: /^([a-zA-Z0-9#$%&\.-\s\']*)$/,
    isValidEmail: /^([A-Za-z0-9]*[A-Za-z][A-Za-z0-9\._-]*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,})$/,
    // allowedCharacter: /^([a-zA-Z\'\"\s0-9?._().,/#$%&!`-]*)$/,
    // surveyname: /^([a-zA-Z\'\"\s0-9?._().,/#$%&!`-]*)$/,
    // HTML: /(<([^>]+)>)/i,
    allowedCharacter: /^(.*?)$/,
    clientName: /^(.*?)$/,
    surveyname: /^(.*?)$/,
    spaceInBeginning: /^(?!\s)/,
});

export const CREATE_SURVEY_TABS = [
    {
        id: 1,
        name: 'Salesforce Information',
        slug: 'salesforce_information',
    },
    {
        id: 2,
        name: 'Client Overview',
        slug: 'client_overview',
    },
    {
        id: 3,
        name: 'Survey Templates',
        slug: 'templates',
    },
    {
        id: 4,
        name: 'Additional Questions',
        slug: 'additional_questions',
    },
    // {
    //   id: 4,
    //   name: "Add Users",
    //   slug: "add_users",
    // },
];

export const CLIENT_USER_TABS = [
    {
        id: 1,
        name: 'All users',
    },
    {
        id: 2,
        name: 'All clients',
    },
];

export const SURVEY_CREATED_LIST = [
    {
        id: 1,
        name: 'No surveys created',
    },
    {
        id: 2,
        name: '1 or more surveys created',
    },
];
export const SIDE_BAR = [
    {
        id: 1,
        label: 'Home',
        url: '/dashboard',
        flag: true,
    },
    {
        id: 2,
        label: 'User Management',
        url: '/user',
        flag: false,
    },
    {
        id: 3,
        label: 'Surveys',
        url: '/surveys',
        flag: true,
    },
    {
        id: 4,
        label: 'Monitor',
        url: '/monitor',
        flag: true,
    },
    {
        id: 5,
        label: 'Analytics',
        url: '/analytics',
        flag: true,
    },
];

export const PERPAGE = [10, 20, 30, 40, 50];

export const PATHS = {
    USER: '/user',
    CLIENT: '/client',
    SURVEYS: '/surveys',
    MONITOR: '/monitor',
    MONITOR_SURVEY: 'monitor-survey',
    ADD_CLIENT: '/client/add',
    EDIT_CLIENT: '/client/edit/:client_id',
    DASHBOARD: '/dashboard',
    HOME: '/',
    AUTH_CALL: '/auth/callback',
    CREATE_SURVEY: '/surveys/create',
    ANALYTICS: '/analytics',
    HELP: '/help',
    KNOWLEDGE_CENTER_HOME: '/knowledge-centre/home',
    KNOWLEDGE_CENTER: '/knowledge-centre',
    KC_OVERVIEW: '/knowledge-centre/overview',
    KC_PRESURVEY: '/knowledge-centre/pre-survey',
    KC_SETUP_SURVEY: '/knowledge-centre/setup-survey',
    KC_LIVE_SURVEY: '/knowledge-centre/live-survey',
    KC_ANALYSIS: '/knowledge-centre/analysis',
    KC_GLOSSARY: '/knowledge-centre/glossary',
};

export const FILTER_STATUS = [
    {
        id: 1,
        name: 'Active',
        is_selected: false,
        value: true,
    },
    {
        id: 2,
        name: 'Inactive',
        is_selected: false,
        value: false,
    },
];

export const ROLE = ['Super Admin', 'Admin', 'Coach', 'CLient', 'CST'];
export const STEPS = {
    BASIC_INFO: 'Basic information',
    SURVEY_SETUP: 'Survey setup',
    TEXT_EDIT: 'Text edit',
    RESPONDENT: 'Respondent upload',
    DISTRIBUTE_SURVEY: 'Distribute survey',
};

export const OPTIONAL_MODULES_LIST = [
    {
        id: 1,
        name: 'Values',
        module_desc: 'Replace this text with few words explaining this module briefly',
        questions: 20,
        is_selected: false,
    },
    {
        id: 2,
        name: 'Influencer',
        module_desc: 'Replace this text with few words explaining this module briefly',
        questions: 12,
        is_selected: false,
    },
];

export const OPEN_END_TABS = [
    {
        id: 'standard',
        name: 'Standard open-text questions',
        disabled: false,
    },
    {
        id: 'custom',
        name: 'Custom open-text questions',
        disabled: true,
    },
];

export let DISPLAY_LOGIC_TAB = [
    {
        id: 'ohi_template_ques',
        name: 'OHI template questions',
        visibleToNonAdmin: false,
    },
    {
        id: 'additional_module_ques',
        name: 'Additional module questions',
        visibleToNonAdmin: false,
    },
    {
        id: 'additional_ques',
        name: 'Additional questions',
        visibleToNonAdmin: false,
    },
    {
        id: 'demographic_ques',
        name: 'Demographic questions',
        visibleToNonAdmin: true,
    },
    {
        id: 'open_text_ques',
        name: 'Open text questions',
        visibleToNonAdmin: false,
    },
];

export const ohi_temp_Tabs = [
    'Direction',
    'Work Environment',
    'Accountability',
    'Coordination & Control',
    'Capabilities',
    'Motivation',
    'Innovation & Learning',
    'External Orientation',
    'Leadership',
];

export const ohi_template_ques = 'ohi_template_ques';
export const additional_module_ques = 'additional_module_ques';
export const additional_ques = 'additional_ques';
export const demographic_ques = 'demographic_ques';
export const open_text_ques = 'open_text_ques';

export const AdditionalModulesQuestList = ['OHI 4.0 Questions', 'Optional Questions'];
export const OHI_4_SubTabList = ['Employee Experience', 'Workplace flexibility'];
export const OptionalQuestionsSubTab = [
    'Employee Experience',
    'Employee Journey',
    'Workplace Flexibility',
    'Practice Questions',
    'Resurvey Questions',
    '3.2 Questions',
];
export const NoResultDisplayLogicSubText = 'You may want to try different keywords or checking for typos';

export const AdditionalQuestList = ['Agreement scale questions', 'Frequency scale questions'];

export const AgreementScaleCode = 'outAlignCustom_modOutcome';
export const FrequencyScaleCode = 'pracAlignCustom_modPractice';
export const Optional_32_Code = 'operational_management_optional';
export const Optional_4_Code = '4_0_questions_optional';
export const display_default_text =
    'This question is fixed and cannot be shown or hidden based on another. However, you can use it to display another question or response.';
export const OPEN_END_TABS_NAME = {
    STANDARD: 'standard',
    CUSTOM: 'custom',
};

export const BRANDING_VIEW = {
    EMPTY: 1,
    IN_PROGRESS: 2,
    COMPLETE: 3,
};

export const LOGO_MAX_FILE_SIZE = 1e6;

export const LOGO_FILE_FORMATS = ['.jpeg', '.jpg', '.png'];

export const LIMIT = 50;
export const QUESTIONS_LIMIT = 5;
export const MAX_SURVEY_LIMIT = 1;

export const Sidebar_Nav = [
    {
        id: 1,
        name: 'Wording',
        WordingAccess: true,
        required: true,
        navIndex: 0,
    },
    {
        id: 2,
        name: 'Survey overview',
        OverviewAccess: true,
        required: true,
        navIndex: 1,
    },
    {
        id: 3,
        name: 'Question modules',
        AdditionalModuleAccess: true,
        required: true,
        navIndex: 2,
    },
    {
        id: 4,
        name: 'Additional questions',
        AdditionalQuestionAccess: true,
        required: true,
        navIndex: 3,
    },
    {
        id: 5,
        name: 'Demographic questions',
        DemoQuestionAccess: true,
        required: true,
        navIndex: 4,
    },
    {
        id: 6,
        name: 'Open-text questions',
        OpenEndQuestionAccess: true,
        required: true,
        navIndex: 5,
    },
    {
        id: 7,
        name: 'Display logic',
        DisplayLogicAccess: true,
        required: true,
        navIndex: 6,
    },
    {
        id: 8,
        name: 'Branding',
        required: true,
        navIndex: 7,
    },
    {
        id: 9,
        name: 'Settings',
        required: true,
        navIndex: 8,
    },
    {
        id: 10,
        name: 'Submit for approval',
        required: true,
        navIndex: 9,
    },
];

export const TABS_TO_NAV_INDEX = {
    ADDITIONAL_QUESTIONS: 3,
    DEMOGRAPHIC_QUESTION: 4,
    OPEN_TEXT_QUESTION: 5,
};

export const All_OPTIONS = [
    {
        id: 1,
        name: 'Accountability',
        checked: true,
    },
    {
        id: 2,
        name: 'Arrogant',
        checked: true,
    },
    {
        id: 3,
        name: 'Autonomy',
        checked: true,
    },
    {
        id: 4,
        name: 'Being collaborative',
        checked: true,
    },
    {
        id: 5,
        name: 'Being competitive',
        checked: false,
    },
    {
        id: 6,
        name: 'Being of service to others',
        checked: false,
    },
    {
        id: 7,
        name: 'Being passionate',
        checked: false,
    },
    {
        id: 8,
        name: 'Bureaucracy',
        checked: false,
    },
    {
        id: 9,
        name: 'Caring',
        checked: true,
    },
    {
        id: 10,
        name: 'Conflict',
        checked: true,
    },
    {
        id: 11,
        name: 'Continuous improvement',
        checked: true,
    },
    {
        id: 12,
        name: 'Contributing to the greater good',
        checked: true,
    },
    {
        id: 13,
        name: 'Customer focus',
        checked: false,
    },
    {
        id: 14,
        name: 'Efficiency',
        checked: false,
    },
    {
        id: 15,
        name: 'Employee focus',
        checked: false,
    },
    {
        id: 16,
        name: 'Empowerment',
        checked: false,
    },
];

export const PREVIEW_OPTIONS = [
    {
        id: 1,
        quesTitle:
            'The company has a vision for the future that is both easy to understand and meaningful to employees',
        quesOptions: [
            {
                option: 'Strongly disagree',
                id: 1,
            },
            {
                option: 'Disagree',
                id: 2,
            },
            {
                option: 'Neutral',
                id: 3,
            },
            {
                option: 'Agree',
                id: 4,
            },
            {
                option: 'Strongly agree',
                id: 5,
            },
            {
                option: 'Don’t know/NA',
                id: 6,
            },
        ],
    },
    {
        id: 2,
        quesTitle: 'The company’s vision is clearly communicated throughout the organization',
        quesOptions: [
            {
                option: 'Almost never',
                id: 1,
            },
            {
                option: 'Seldom',
                id: 2,
            },
            {
                option: 'Sometimes',
                id: 3,
            },
            {
                option: 'Often',
                id: 4,
            },
            {
                option: 'Almost always',
                id: 5,
            },
            {
                option: 'Don’t know/NA',
                id: 6,
            },
        ],
    },
    {
        id: 3,
        quesTitle: 'The company’s strategy is aligned with its vision',
        quesOptions: [
            {
                option: 'Strongly disagree',
                id: 1,
            },
            {
                option: 'Disagree',
                id: 2,
            },
            {
                option: 'Neutral',
                id: 3,
            },
            {
                option: 'Agree',
                id: 4,
            },
            {
                option: 'Strongly agree',
                id: 5,
            },
            {
                option: 'Don’t know/NA',
                id: 6,
            },
        ],
    },
];

export const SETTINGS_LAYOUT_OPTIONS = {
    SINGLE_QUESTION: 'single_question',
    LONG_SCROLL: 'long_scroll',
    QUESTION_GROUPS: 'question_groups',
    CHAT: 'chat',
};

export const SURVEY_STATUS = Object.freeze({
    DRAFT: 'DRAFT',
    LIVE: 'LIVE',
    UNDER: 'UNDER_REVIEW',
    REVIEWED: 'REVIEWED',
    COMPLETED: 'COMPLETED',
    DELETED: 'DELETED',
    LOCKED: 'LOCKED',
});

export const SURVEY_STATUS_UI = Object.freeze({
    DRAFT: 'DRAFT',
    LIVE: 'LIVE',
    UNDER_REVIEW: 'UNDER REVIEW',
    REVIEWED: 'REVIEWED',
    COMPLETED: 'COMPLETED',
});

export const ACTUAL_STATUS = Object.freeze({
    DRAFT: 'Draft',
    LIVE: 'Live',
    UNDER_REVIEW: 'Under Review',
    REVIEWED: 'Reviewed',
    COMPLETED: 'Closed',
});

export const QUES_STATUS_TYPE = Object.freeze({
    NOT_SUBMITTED: 'Not Submitted',
    PENDING: 'Pending for Approval',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
});

export const GET_CLASS = Object.freeze({
    NOT_SUBMITTED: 'notsubmitted',
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
});

export const QUES_TYPE_COLL = [
    {
        value: QUES_STATUS_TYPE.NOT_SUBMITTED,
        slug: 'notsubmitted',
    },
    {
        value: QUES_STATUS_TYPE.PENDING,
        slug: 'pending',
    },
    {
        value: QUES_STATUS_TYPE.APPROVED,
        slug: 'approved',
    },
    {
        value: QUES_STATUS_TYPE.REJECTED,
        slug: 'rejected',
    },
];

export const MONITOR_SURVEY_TABS = [
    {
        id: 'monitoring',
        name: 'Monitoring',
        disabled: false,
    },
    {
        id: 'closing_protocol',
        name: 'Closing Protocol',
        disabled: false,
    },
];
export const maintenanceModeList = [
    { value: 'Both', name: 'Both' },
    { value: 'ENABLE', name: 'Enable' },
    { value: 'DISABLE', name: 'Disable' },
];

export const MONITOR_SURVEY_TABS_NAME = {
    MONITORING: 'monitoring',
    CLOSING_PROTOCOL: 'closing_protocol',
};

export const SURVEY_TYPE = {
    GENERIC: 'GENERIC',
    INDIVIDUAL: 'INDIVIDUAL',
    INDIVIDUAL_PLUS_GENERIC: 'INDIVIDUAL_PLUS_GENERIC',
};

export const SURVEY_TYPE_MAPPING = {
    GENERIC: {
        label: 'Generic',
        value: 'GENERIC',
    },
    INDIVIDUAL: {
        label: 'Individual',
        value: 'INDIVIDUAL',
    },
    INDIVIDUAL_PLUS_GENERIC: {
        label: 'Individual + Generic',
        value: 'INDIVIDUAL_PLUS_GENERIC',
    },
};

export const initialSelectedFilters = {
    status: [],
    link_type: [],
    template_type: [],
    survey_language: [],
    is_test: false,
};
export const initialFilterConfig = {
    limit: 10,
    page: 1,
    searchParam: '',
    dateRange: { startDate: null, endDate: null },
};

export const OVERVIEW_META_KEYS = [
    'intro_heading',
    'survey_description',
    'intro_legal',
    'intro_time',
    'pss_intro_time',
    'intro_note',
    'intro_question',
    'intro_copyright',
];

export const intropage = 'intro_page_2';
export const OVERVIEW_ALLOWED = {
    1: ['intro_heading', 'survey_description', 'intro_legal', 'intro_question', 'intro_copyright'],
    2: ['survey_instructions_text', 'intro_page_2', 'intro_time', 'pss_intro_time'],
    3: [''],
    4: ['primary_passcode_instruction', 'additional_passcode_instruction'],
};

export const OVERVIEW_HEADING = Object.freeze({
    intro_heading: 'Heading',
    survey_instructions_text: 'Heading',
    survey_description: 'Introductory text (recommended 50-100 words)',
    survey_description_npm: 'Introductory text',
    intro_legal: 'Legal information',
    intro_time: 'Time',
    pss_intro_time: 'Time',
    intro_note: 'Note',
    intro_question: 'Questions',
    intro_copyright: 'Copyright',
    intro_page_2: 'Instructions for participants',
    primary_passcode_instruction: 'Primary instruction',
    additional_passcode_instruction: 'Additional instructions',
    enter_passcode_text: 'Enter your passcode (Information message)',
});

export const DISTRIBUTE_SURVEY_NAV = [
    {
        id: 1,
        name: 'Survey Link',
        navIndex: 0,
    },
    {
        id: 2,
        name: 'Emailers',
        navIndex: 1,
    },
    {
        id: 3,
        name: 'Schedule',
        navIndex: 2,
    },
];

export const RESPONDENT_TAB = [
    {
        id: 1,
        name: 'Respondents',
        navIndex: 0,
    },
];

export const REPLACE_CHARS = {
    '<': '',
    '>': '',
    '*': '',
    '=': '',
};

export const BASE_VALUE = 5;
export const Default_Lang = 'en';
export const DISABLE_TAB = [0];
export const THROTTLE_TIME = 500;
export const EXPORT_DATA = [
    {
        id: 1,
        name: 'Response rate',
        slug: 'export',
        title: 'Excel download of responses broken down by demographics',
        visibleToAll: true,
    },
    {
        id: 2,
        name: 'Data file (Codes)',
        slug: 'CODED',
        title: 'Excel data file with question IDs and answer codes',
        visibleToAll: false,
    },
    {
        id: 3,
        name: 'Data file (Labels)',
        slug: 'LABELED',
        title: 'Excel data file with question text and answer labels',
        visibleToAll: false,
    },
];

export const TEXT_EDIT_NAV = [
    {
        id: 1,
        name: 'Survey overview',
        SurveyOverviewAccess: true,
        navIndex: 0,
    },
    {
        id: 2,
        name: 'OHI template questions',
        surveyOverviewAccess: true,
        navIndex: 1,
    },
    {
        id: 3,
        name: 'Question modules',
        questionModulesAccess: true,
        navIndex: 2,
    },
    {
        id: 4,
        name: 'Additional questions',
        additionalQuesAccess: true,
        navIndex: 3,
    },
    {
        id: 5,
        name: 'Demographics',
        demographicAccess: true,
        navIndex: 4,
    },
    {
        id: 6,
        name: 'Open text questions',
        openTextAccess: true,
        navIndex: 5,
    },
    {
        id: 7,
        name: 'System Messages',
        systemMessagesAccess: true,
        navIndex: 6,
    },
];
export const LANG_TITLE = 'Please visit each language option to review/customize the survey wording';
export const Tab_Type = {
    survey: 'surveySetup',
    textEdit: 'textEdit',
};
export const Tools = {
    intro_heading: ['bold', 'italic'],
    survey_description: ['bold', 'italic'],
    intro_legal: ['link', '|', 'bold', 'italic'],
    intro_time: ['bold', 'italic'],
    pss_intro_time: ['bold', 'italic'],
    intro_note: ['bold', 'italic'],
    intro_question: ['link', '|', 'bold', 'italic'],
    intro_copyright: [],
    intro_page_2: ['bold', 'italic'],
    survey_instructions_text: ['bold', 'italic'],
    additional_passcode_instruction: ['bold', 'italic'],
};

export const NPM_TEMPLATE = 'template_ohi_npm';
export const LTE_TEMPLATE = 'template_ohi_lte';
export const OHI_32_TEMPLATE = 'template_ohi_32';
export const OHI_40_TEMPLATE = 'template_ohi_40';

export const pulseSurveyTypes = ['template_ohi_32', 'template_ohi_40', 'template_ohi'];

export const surveyTypes = ['FULL_OHI', 'PULSE'];

export const FULL_OHI = 'FULL_OHI';
export const PULSE = 'PULSE';

export const full_surveyType = 'Full';
export const pulse_surveyType = 'Pulse';

export const SELECT_LOGIC = 'Select logic';
export const Question = 'Question';
export const Set_Question_Logic = 'Set logic for showing question';
export const Set_Response_Logic = 'Set logic for showing responses';
export const NO_DATA_FOUND = ' No Data Found';
export const ADD_LOGIC = 'Add logic';
export const Responses = 'Responses';
export const TEXT_EDIT_GROUPS = Object.freeze({
    ALL: 'ALL',
    OHI_TEMPLATE: 'OHI_TEMPLATE',
    OPEN_TEXT: 'OPEN_TEXT',
    ADDITIONAL: 'ADDITIONAL',
    DEMOGRAPHIC: 'DEMOGRAPHIC',
    SYSTEM_MESSAGES: 'SYSTEM_MESSAGES',
    QUESTION_MODULES: 'QUESTION_MODULES',
    SURVEY_OVERVIEW: 'SURVEY_OVERVIEW',
});

export const API_SUCCESS_CODES = [200];

export const ShowLogicInfo =
    "Turning off the toggle will hide any logo. If the toggle is on, the default logo shown will be McKinsey's. You also have the option to upload a custom logo below.";

export const uploadRespondentDisclaimer =
    'IMPORTANT: Please use a BOX sync folder to manage client HR data as per legal and risk guidelines';

export const scheduleSurveyDisclaimer =
    'In draft mode, emails can only be scheduled for respondents designated as "Test". Upon finalizing the survey, email scheduling becomes available for all respondents (Test / live)';

export const maintenance_tooltip = 'This survey is under maintenance mode. Please update status to enable the link';
